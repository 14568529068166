<table class="width-full mt-4">
  <thead>
    <tr>
      <th>Number of SNFs</th>
      <th>Base Cost</th>
      <th>Reporting Cost</th>
      <th>Total Cost</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="pr-4">
        <form [formGroup]="form" novalidate>
          <mat-form-field class="width-full" appearance="outline">
            <mat-select formControlName="count">
              <mat-option
                *ngFor="let num of counts; let i = index"
                [value]="i + 1"
              >
                {{ i + 1 }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </td>
      <td>$ 1249.00</td>
      <td>{{ ((calc(form.value.count, []).price / 100) - 1249) | currency }}</td>
      <td>{{ (calc(form.value.count, []).price / 100) | currency }}</td>
    </tr>
  </tbody>
</table>
