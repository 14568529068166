<evo-modal-page>
  <form class="mt-2" [formGroup]="formGroupMfa">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>pin</mat-icon>
      <input
        matInput
        type="text"
        formControlName="mfa"
        placeholder="code"
        #input
        autofocus
      />
    </mat-form-field>

    <button
      color="primary"
      class="xl"
      *ngIf="!(busy$ | async)"
      (click)="validateMfa(formGroupMfa.value.mfa)"
      [disabled]="
        (busy$ | async) || formGroupMfa.invalid || formGroupMfa.pristine
      "
      mat-raised-button
    >
      Validate
    </button>
    <div *ngIf="busy$ | async" class="row">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
  </form>
</evo-modal-page>
