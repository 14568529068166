<evo-modal-page>
  <div *ngIf="!(busy$ | async)">
    <h1 class="text-center" *ngIf="!success">
      There was a problem verifying your email
    </h1>
    <h1 class="text-center" *ngIf="success">Your email has been validated.</h1>
    <h2 class="text-center" *ngIf="success">
      The next step is to validate your phone number.
    </h2>
    <div class="row center" *ngIf="success">
      <a
        mat-raised-button
        class="xl width-full"
        color="primary"
        [routerLink]="['/', 'phone', hash$ | async]"
        >Validate Phone</a
      >
    </div>
  </div>
  <div *ngIf="busy$ | async">
    <h1 class="text-center">Validating your email...</h1>
    <div class="row center">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
  </div>
</evo-modal-page>
