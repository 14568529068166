/* tslint:disable */
/* eslint-disable */
/**
 * RevenueApiModule
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipcode'?: string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'incomeTurnedOverStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'otherAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'other_assets'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'ownRealestateYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'pensionAmt'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Asset
     */
    'realestateAddress'?: Address;
    /**
     * 
     * @type {AssetResident}
     * @memberof Asset
     */
    'resident'?: AssetResident;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'socialSecurityAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'socialSecurityDisabilityAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'supplementalSecurityAmt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'unknownYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'vaAmt'?: string;
}
/**
 * 
 * @export
 * @interface AssetResident
 */
export interface AssetResident {
    /**
     * 
     * @type {Address}
     * @memberof AssetResident
     */
    'incomeControllerAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof AssetResident
     */
    'incomeControllerFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetResident
     */
    'incomeControllerLastName'?: string;
}
/**
 * 
 * @export
 * @interface AuthCredentialsDto
 */
export interface AuthCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof AuthCredentialsDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AuthCredentialsDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AuthMfaDto
 */
export interface AuthMfaDto {
    /**
     * 
     * @type {string}
     * @memberof AuthMfaDto
     */
    'mfa': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMfaDto
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface AuthTokensDto
 */
export interface AuthTokensDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokensDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokensDto
     */
    'idToken': string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'clientId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    'active'?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof Client
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'clientname': string;
    /**
     * 
     * @type {Array<Facility>}
     * @memberof Client
     */
    'facility'?: Array<Facility>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Client
     */
    'users'?: Array<User>;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'contactname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface Counsel
 */
export interface Counsel {
    /**
     * 
     * @type {Address}
     * @memberof Counsel
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Counsel
     */
    'attorney'?: string;
    /**
     * 
     * @type {string}
     * @memberof Counsel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Counsel
     */
    'firm'?: string;
    /**
     * 
     * @type {string}
     * @memberof Counsel
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface Court
 */
export interface Court {
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    'case'?: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    'date_filed'?: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    'docket'?: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    'judge'?: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Debt
 */
export interface Debt {
    /**
     * 
     * @type {string}
     * @memberof Debt
     */
    'balanceAccruingYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Debt
     */
    'currentBalance'?: string;
    /**
     * 
     * @type {string}
     * @memberof Debt
     */
    'estateClaimYn'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Debt
     */
    'unpaidItems'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'active'?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof Facility
     */
    'address'?: Address;
    /**
     * 
     * @type {Contact}
     * @memberof Facility
     */
    'contact'?: Contact;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'facilityname': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'clientId': string;
    /**
     * 
     * @type {Client}
     * @memberof Facility
     */
    'client'?: Client;
}
/**
 * 
 * @export
 * @interface Guardian
 */
export interface Guardian {
    /**
     * 
     * @type {Address}
     * @memberof Guardian
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guardian
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface HealthDto
 */
export interface HealthDto {
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'free': number;
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'percentUtilization': number;
    /**
     * 
     * @type {string}
     * @memberof HealthDto
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface Matter
 */
export interface Matter {
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'matterId': string;
    /**
     * 
     * @type {Asset}
     * @memberof Matter
     */
    'asset'?: Asset;
    /**
     * 
     * @type {Court}
     * @memberof Matter
     */
    'court'?: Court;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'clientId': string;
    /**
     * 
     * @type {Client}
     * @memberof Matter
     */
    'client': Client;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'createdById'?: string;
    /**
     * 
     * @type {User}
     * @memberof Matter
     */
    'createdBy'?: User;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'creationCategory': string;
    /**
     * 
     * @type {number}
     * @memberof Matter
     */
    'creationStatus': number;
    /**
     * 
     * @type {Debt}
     * @memberof Matter
     */
    'debt'?: Debt;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'facilityId': string;
    /**
     * 
     * @type {Facility}
     * @memberof Matter
     */
    'facility': Facility;
    /**
     * 
     * @type {Guardian}
     * @memberof Matter
     */
    'guardian'?: Guardian;
    /**
     * 
     * @type {boolean}
     * @memberof Matter
     */
    'isClientCreated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'matterNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'matterSortOrder'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'matterStatus': string;
    /**
     * 
     * @type {Medicaid}
     * @memberof Matter
     */
    'medicaid'?: Medicaid;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'modifiedById'?: string;
    /**
     * 
     * @type {User}
     * @memberof Matter
     */
    'modifiedBy'?: User;
    /**
     * 
     * @type {Counsel}
     * @memberof Matter
     */
    'opposingCounsel'?: Counsel;
    /**
     * 
     * @type {Poa}
     * @memberof Matter
     */
    'poa'?: Poa;
    /**
     * 
     * @type {Resident}
     * @memberof Matter
     */
    'resident'?: Resident;
    /**
     * 
     * @type {ResponsibleParty}
     * @memberof Matter
     */
    'responsibleParty'?: ResponsibleParty;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'respAttorney'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'keyNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Matter
     */
    'serviceId': string;
    /**
     * 
     * @type {Service}
     * @memberof Matter
     */
    'serviceRequested': Service;
    /**
     * 
     * @type {Spouse}
     * @memberof Matter
     */
    'spouse'?: Spouse;
}
/**
 * 
 * @export
 * @interface MatterDocument
 */
export interface MatterDocument {
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docId': string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterDocument
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'clientId': string;
    /**
     * 
     * @type {Client}
     * @memberof MatterDocument
     */
    'client': Client;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'createdById': string;
    /**
     * 
     * @type {User}
     * @memberof MatterDocument
     */
    'createdBy': User;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docName': string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docKey': string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docFilename': string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docSection'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'docUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterDocument
     */
    'isClientCreated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'matterId': string;
    /**
     * 
     * @type {Matter}
     * @memberof MatterDocument
     */
    'matter': Matter;
    /**
     * 
     * @type {User}
     * @memberof MatterDocument
     */
    'modifiedBy'?: User;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'modifiedById'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof MatterDocument
     */
    'uploadedBy'?: User;
    /**
     * 
     * @type {string}
     * @memberof MatterDocument
     */
    'uploadedById'?: string;
}
/**
 * 
 * @export
 * @interface MatterNote
 */
export interface MatterNote {
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterNote
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'clientId': string;
    /**
     * 
     * @type {Client}
     * @memberof MatterNote
     */
    'client': Client;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'createdById': string;
    /**
     * 
     * @type {User}
     * @memberof MatterNote
     */
    'createdBy': User;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterNote
     */
    'isClientShared'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'matterId': string;
    /**
     * 
     * @type {Matter}
     * @memberof MatterNote
     */
    'matter': Matter;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof MatterNote
     */
    'modifiedBy'?: User;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'modifiedById'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'noteStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterNote
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface MatterSearchDto
 */
export interface MatterSearchDto {
    /**
     * 
     * @type {Array<Matter>}
     * @memberof MatterSearchDto
     */
    'data': Array<Matter>;
    /**
     * 
     * @type {number}
     * @memberof MatterSearchDto
     */
    'pagingKey': number;
    /**
     * 
     * @type {number}
     * @memberof MatterSearchDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface MatterSnapshotDto
 */
export interface MatterSnapshotDto {
    /**
     * 
     * @type {Matter}
     * @memberof MatterSnapshotDto
     */
    'matter': Matter;
    /**
     * 
     * @type {Array<MatterNote>}
     * @memberof MatterSnapshotDto
     */
    'matterNotes': Array<MatterNote>;
    /**
     * 
     * @type {Array<MatterStatus>}
     * @memberof MatterSnapshotDto
     */
    'matterStatus': Array<MatterStatus>;
    /**
     * 
     * @type {Array<MatterTask>}
     * @memberof MatterSnapshotDto
     */
    'matterTasks': Array<MatterTask>;
}
/**
 * 
 * @export
 * @interface MatterStatus
 */
export interface MatterStatus {
    /**
     * 
     * @type {string}
     * @memberof MatterStatus
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterStatus
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterStatus
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterStatus
     */
    'createdById': string;
    /**
     * 
     * @type {User}
     * @memberof MatterStatus
     */
    'createdBy': User;
    /**
     * 
     * @type {string}
     * @memberof MatterStatus
     */
    'matterId': string;
    /**
     * 
     * @type {Matter}
     * @memberof MatterStatus
     */
    'matter': Matter;
    /**
     * 
     * @type {string}
     * @memberof MatterStatus
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface MatterTask
 */
export interface MatterTask {
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterTask
     */
    'active'?: boolean;
    /**
     * 
     * @type {User}
     * @memberof MatterTask
     */
    'assignee'?: User;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'assigneeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'clientId'?: string;
    /**
     * 
     * @type {Client}
     * @memberof MatterTask
     */
    'client'?: Client;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'createdById': string;
    /**
     * 
     * @type {User}
     * @memberof MatterTask
     */
    'createdBy': User;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'matterId': string;
    /**
     * 
     * @type {Matter}
     * @memberof MatterTask
     */
    'matter': Matter;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof MatterTask
     */
    'modifiedBy'?: User;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'modifiedById'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'taskSortOrder'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MatterTask
     */
    'taskStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatterTask
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface MatterTaskSearchDto
 */
export interface MatterTaskSearchDto {
    /**
     * 
     * @type {Array<MatterTask>}
     * @memberof MatterTaskSearchDto
     */
    'data': Array<MatterTask>;
    /**
     * 
     * @type {number}
     * @memberof MatterTaskSearchDto
     */
    'pagingKey': number;
    /**
     * 
     * @type {number}
     * @memberof MatterTaskSearchDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface Medicaid
 */
export interface Medicaid {
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'actionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'actionReason'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Medicaid
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'authorizedRepFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'authorizedRepLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'effectiveStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'infoProvided'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'repCooperatingYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Medicaid
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface Payor
 */
export interface Payor {
    /**
     * 
     * @type {string}
     * @memberof Payor
     */
    'coverageTerminationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payor
     */
    'covergaeTerminationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payor
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Poa
 */
export interface Poa {
    /**
     * 
     * @type {Address}
     * @memberof Poa
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Poa
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Poa
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Poa
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Resident
 */
export interface Resident {
    /**
     * 
     * @type {Address}
     * @memberof Resident
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'admissionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'competentStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'dischargeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'dischargedYN'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'dischargeLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'dod'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'middleName'?: string;
    /**
     * 
     * @type {Payor}
     * @memberof Resident
     */
    'payor'?: Payor;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    'vitalStatus'?: string;
}
/**
 * 
 * @export
 * @interface ResponsibleParty
 */
export interface ResponsibleParty {
    /**
     * 
     * @type {Address}
     * @memberof ResponsibleParty
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ResponsibleParty
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsibleParty
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsibleParty
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'serviceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Service
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'serviceName': string;
}
/**
 * 
 * @export
 * @interface Spouse
 */
export interface Spouse {
    /**
     * 
     * @type {Address}
     * @memberof Spouse
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Spouse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Spouse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Spouse
     */
    'responsiblePartyYn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Spouse
     */
    'spouseStatus'?: string;
}
/**
 * 
 * @export
 * @interface UrlDto
 */
export interface UrlDto {
    /**
     * 
     * @type {string}
     * @memberof UrlDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'memberId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'memberType': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mobile': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userName': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'sendNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'summaryNotification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'clientId': string;
    /**
     * 
     * @type {Client}
     * @memberof User
     */
    'client': Client;
}
/**
 * 
 * @export
 * @interface ValueDto
 */
export interface ValueDto {
    /**
     * 
     * @type {string}
     * @memberof ValueDto
     */
    'value': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attorneyControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/attorney`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate: async (authCredentialsDto: AuthCredentialsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsDto' is not null or undefined
            assertParamExists('authControllerAuthenticate', 'authCredentialsDto', authCredentialsDto)
            const localVarPath = `/api/auth/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (authCredentialsDto: AuthCredentialsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsDto' is not null or undefined
            assertParamExists('authControllerLogin', 'authCredentialsDto', authCredentialsDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateMfaCode: async (authMfaDto: AuthMfaDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authMfaDto' is not null or undefined
            assertParamExists('authControllerValidateMfaCode', 'authMfaDto', authMfaDto)
            const localVarPath = `/api/auth/validate-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authMfaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientControllerGetClient', 'id', id)
            const localVarPath = `/api/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClientFacilities: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientControllerGetClientFacilities', 'id', id)
            const localVarPath = `/api/client/{id}/facility`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClientUsers: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientControllerGetClientUsers', 'id', id)
            const localVarPath = `/api/client/{id}/user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Client} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerPutClient: async (id: string, client: Client, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientControllerPutClient', 'id', id)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('clientControllerPutClient', 'client', client)
            const localVarPath = `/api/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadControllerDownload: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('downloadControllerDownload', 'key', key)
            const localVarPath = `/api/download/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerGetFacility: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilityControllerGetFacility', 'id', id)
            const localVarPath = `/api/facility/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerGetFacilitys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/facility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Facility} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerPutFacility: async (id: string, facility: Facility, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilityControllerPutFacility', 'id', id)
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('facilityControllerPutFacility', 'facility', facility)
            const localVarPath = `/api/facility/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facility, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerGet', 'id', id)
            const localVarPath = `/api/matter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterDocuments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerGetMatterDocuments', 'id', id)
            const localVarPath = `/api/matter/{id}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [createdby] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterNotes: async (id: string, createdby?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerGetMatterNotes', 'id', id)
            const localVarPath = `/api/matter/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (createdby !== undefined) {
                localVarQueryParameter['createdby'] = createdby;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterSnapshot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerGetMatterSnapshot', 'id', id)
            const localVarPath = `/api/matter/{id}/snapshot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [matterstatus] 
         * @param {any} [matterid] 
         * @param {any} [creationstatus] 
         * @param {any} [creationcategory] 
         * @param {any} [createdby] 
         * @param {any} [search] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [facilityid] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerIndex: async (matterstatus?: any, matterid?: any, creationstatus?: any, creationcategory?: any, createdby?: any, search?: any, pagesize?: any, pagingkey?: any, facilityid?: any, clientid?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/matter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (matterstatus !== undefined) {
                localVarQueryParameter['matterstatus'] = matterstatus;
            }

            if (matterid !== undefined) {
                localVarQueryParameter['matterid'] = matterid;
            }

            if (creationstatus !== undefined) {
                localVarQueryParameter['creationstatus'] = creationstatus;
            }

            if (creationcategory !== undefined) {
                localVarQueryParameter['creationcategory'] = creationcategory;
            }

            if (createdby !== undefined) {
                localVarQueryParameter['createdby'] = createdby;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagingkey !== undefined) {
                localVarQueryParameter['pagingkey'] = pagingkey;
            }

            if (facilityid !== undefined) {
                localVarQueryParameter['facilityid'] = facilityid;
            }

            if (clientid !== undefined) {
                localVarQueryParameter['clientid'] = clientid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Matter} matter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPostMatter: async (matter: Matter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matter' is not null or undefined
            assertParamExists('matterControllerPostMatter', 'matter', matter)
            const localVarPath = `/api/matter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Debt} debt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutDebt: async (id: string, debt: Debt, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutDebt', 'id', id)
            // verify required parameter 'debt' is not null or undefined
            assertParamExists('matterControllerPutDebt', 'debt', debt)
            const localVarPath = `/api/matter/{id}/debt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(debt, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutKeyNotes: async (id: string, valueDto: ValueDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutKeyNotes', 'id', id)
            // verify required parameter 'valueDto' is not null or undefined
            assertParamExists('matterControllerPutKeyNotes', 'valueDto', valueDto)
            const localVarPath = `/api/matter/{id}/key-notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Court} court 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutMatterCourt: async (id: string, court: Court, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutMatterCourt', 'id', id)
            // verify required parameter 'court' is not null or undefined
            assertParamExists('matterControllerPutMatterCourt', 'court', court)
            const localVarPath = `/api/matter/{id}/court`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(court, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Resident} resident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutMatterResident: async (id: string, resident: Resident, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutMatterResident', 'id', id)
            // verify required parameter 'resident' is not null or undefined
            assertParamExists('matterControllerPutMatterResident', 'resident', resident)
            const localVarPath = `/api/matter/{id}/resident`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resident, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Counsel} counsel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutOpposingCounsel: async (id: string, counsel: Counsel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutOpposingCounsel', 'id', id)
            // verify required parameter 'counsel' is not null or undefined
            assertParamExists('matterControllerPutOpposingCounsel', 'counsel', counsel)
            const localVarPath = `/api/matter/{id}/opposing-counsel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(counsel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutRespAttorney: async (id: string, valueDto: ValueDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutRespAttorney', 'id', id)
            // verify required parameter 'valueDto' is not null or undefined
            assertParamExists('matterControllerPutRespAttorney', 'valueDto', valueDto)
            const localVarPath = `/api/matter/{id}/resp-attorney`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ResponsibleParty} responsibleParty 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutResponsibleParty: async (id: string, responsibleParty: ResponsibleParty, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterControllerPutResponsibleParty', 'id', id)
            // verify required parameter 'responsibleParty' is not null or undefined
            assertParamExists('matterControllerPutResponsibleParty', 'responsibleParty', responsibleParty)
            const localVarPath = `/api/matter/{id}/responsible-party`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(responsibleParty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterTaskControllerGet', 'id', id)
            const localVarPath = `/api/matter-task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [facility] 
         * @param {any} [search] 
         * @param {any} [maxduedate] 
         * @param {any} [minduedate] 
         * @param {any} [assigneeid] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerIndex: async (facility?: any, search?: any, maxduedate?: any, minduedate?: any, assigneeid?: any, pagesize?: any, pagingkey?: any, clientid?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/matter-task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (maxduedate !== undefined) {
                localVarQueryParameter['maxduedate'] = maxduedate;
            }

            if (minduedate !== undefined) {
                localVarQueryParameter['minduedate'] = minduedate;
            }

            if (assigneeid !== undefined) {
                localVarQueryParameter['assigneeid'] = assigneeid;
            }

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagingkey !== undefined) {
                localVarQueryParameter['pagingkey'] = pagingkey;
            }

            if (clientid !== undefined) {
                localVarQueryParameter['clientid'] = clientid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MatterTask} matterTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPut: async (id: string, matterTask: MatterTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterTaskControllerPut', 'id', id)
            // verify required parameter 'matterTask' is not null or undefined
            assertParamExists('matterTaskControllerPut', 'matterTask', matterTask)
            const localVarPath = `/api/matter-task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matterTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPutActive: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterTaskControllerPutActive', 'id', id)
            const localVarPath = `/api/matter-task/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPutInactive: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matterTaskControllerPutInactive', 'id', id)
            const localVarPath = `/api/matter-task/{id}/inactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [active] 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {string} [membertype] 
         * @param {string} [memberid] 
         * @param {string} [pagingkey] 
         * @param {number} [pagesize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueUserControllerGetUsers: async (active?: string, username?: string, email?: string, membertype?: string, memberid?: string, pagingkey?: string, pagesize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (membertype !== undefined) {
                localVarQueryParameter['membertype'] = membertype;
            }

            if (memberid !== undefined) {
                localVarQueryParameter['memberid'] = memberid;
            }

            if (pagingkey !== undefined) {
                localVarQueryParameter['pagingkey'] = pagingkey;
            }

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetService: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetServiceNotes: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceControllerGetServiceNotes', 'id', id)
            const localVarPath = `/api/service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadControllerPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attorneyControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attorneyControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAuthenticate(authCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokensDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokensDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerValidateMfaCode(authMfaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientControllerGetClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientControllerGetClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientControllerGetClientFacilities(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Facility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientControllerGetClientFacilities(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientControllerGetClientUsers(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientControllerGetClientUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientControllerGetClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientControllerGetClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Client} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientControllerPutClient(id: string, client: Client, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientControllerPutClient(id, client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadControllerDownload(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadControllerDownload(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilityControllerGetFacility(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilityControllerGetFacility(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilityControllerGetFacilitys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Facility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilityControllerGetFacilitys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Facility} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilityControllerPutFacility(id: string, facility: Facility, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilityControllerPutFacility(id, facility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerGetMatterDocuments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatterDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerGetMatterDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [createdby] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerGetMatterNotes(id: string, createdby?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatterNote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerGetMatterNotes(id, createdby, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerGetMatterSnapshot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatterSnapshotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerGetMatterSnapshot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [matterstatus] 
         * @param {any} [matterid] 
         * @param {any} [creationstatus] 
         * @param {any} [creationcategory] 
         * @param {any} [createdby] 
         * @param {any} [search] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [facilityid] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerIndex(matterstatus?: any, matterid?: any, creationstatus?: any, creationcategory?: any, createdby?: any, search?: any, pagesize?: any, pagingkey?: any, facilityid?: any, clientid?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatterSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerIndex(matterstatus, matterid, creationstatus, creationcategory, createdby, search, pagesize, pagingkey, facilityid, clientid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Matter} matter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPostMatter(matter: Matter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPostMatter(matter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Debt} debt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutDebt(id: string, debt: Debt, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutDebt(id, debt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutKeyNotes(id: string, valueDto: ValueDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutKeyNotes(id, valueDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Court} court 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutMatterCourt(id: string, court: Court, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutMatterCourt(id, court, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Resident} resident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutMatterResident(id: string, resident: Resident, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutMatterResident(id, resident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Counsel} counsel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutOpposingCounsel(id: string, counsel: Counsel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutOpposingCounsel(id, counsel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutRespAttorney(id: string, valueDto: ValueDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutRespAttorney(id, valueDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ResponsibleParty} responsibleParty 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterControllerPutResponsibleParty(id: string, responsibleParty: ResponsibleParty, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterControllerPutResponsibleParty(id, responsibleParty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterTaskControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatterTask>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterTaskControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [facility] 
         * @param {any} [search] 
         * @param {any} [maxduedate] 
         * @param {any} [minduedate] 
         * @param {any} [assigneeid] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterTaskControllerIndex(facility?: any, search?: any, maxduedate?: any, minduedate?: any, assigneeid?: any, pagesize?: any, pagingkey?: any, clientid?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatterTaskSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterTaskControllerIndex(facility, search, maxduedate, minduedate, assigneeid, pagesize, pagingkey, clientid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MatterTask} matterTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterTaskControllerPut(id: string, matterTask: MatterTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterTaskControllerPut(id, matterTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterTaskControllerPutActive(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterTaskControllerPutActive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matterTaskControllerPutInactive(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matterTaskControllerPutInactive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [active] 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {string} [membertype] 
         * @param {string} [memberid] 
         * @param {string} [pagingkey] 
         * @param {number} [pagesize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueUserControllerGetUsers(active?: string, username?: string, email?: string, membertype?: string, memberid?: string, pagingkey?: string, pagesize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueUserControllerGetUsers(active, username, email, membertype, memberid, pagingkey, pagesize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerGetService(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerGetService(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerGetServiceNotes(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerGetServiceNotes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadControllerPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadControllerPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attorneyControllerIndex(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.attorneyControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerAuthenticate(authCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: any): AxiosPromise<AuthTokensDto> {
            return localVarFp.authControllerLogin(authCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: any): AxiosPromise<AuthTokensDto> {
            return localVarFp.authControllerValidateMfaCode(authMfaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClient(id: string, options?: any): AxiosPromise<Client> {
            return localVarFp.clientControllerGetClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClientFacilities(id: string, options?: any): AxiosPromise<Array<Facility>> {
            return localVarFp.clientControllerGetClientFacilities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClientUsers(id: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.clientControllerGetClientUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerGetClients(options?: any): AxiosPromise<Array<Client>> {
            return localVarFp.clientControllerGetClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Client} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientControllerPutClient(id: string, client: Client, options?: any): AxiosPromise<void> {
            return localVarFp.clientControllerPutClient(id, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadControllerDownload(key: string, options?: any): AxiosPromise<UrlDto> {
            return localVarFp.downloadControllerDownload(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerGetFacility(id: string, options?: any): AxiosPromise<Facility> {
            return localVarFp.facilityControllerGetFacility(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerGetFacilitys(options?: any): AxiosPromise<Array<Facility>> {
            return localVarFp.facilityControllerGetFacilitys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Facility} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityControllerPutFacility(id: string, facility: Facility, options?: any): AxiosPromise<void> {
            return localVarFp.facilityControllerPutFacility(id, facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGet(options?: any): AxiosPromise<HealthDto> {
            return localVarFp.healthControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterDocuments(id: string, options?: any): AxiosPromise<Array<MatterDocument>> {
            return localVarFp.matterControllerGetMatterDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [createdby] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterNotes(id: string, createdby?: string, options?: any): AxiosPromise<Array<MatterNote>> {
            return localVarFp.matterControllerGetMatterNotes(id, createdby, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerGetMatterSnapshot(id: string, options?: any): AxiosPromise<MatterSnapshotDto> {
            return localVarFp.matterControllerGetMatterSnapshot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [matterstatus] 
         * @param {any} [matterid] 
         * @param {any} [creationstatus] 
         * @param {any} [creationcategory] 
         * @param {any} [createdby] 
         * @param {any} [search] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [facilityid] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerIndex(matterstatus?: any, matterid?: any, creationstatus?: any, creationcategory?: any, createdby?: any, search?: any, pagesize?: any, pagingkey?: any, facilityid?: any, clientid?: any, options?: any): AxiosPromise<MatterSearchDto> {
            return localVarFp.matterControllerIndex(matterstatus, matterid, creationstatus, creationcategory, createdby, search, pagesize, pagingkey, facilityid, clientid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Matter} matter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPostMatter(matter: Matter, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPostMatter(matter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Debt} debt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutDebt(id: string, debt: Debt, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutDebt(id, debt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutKeyNotes(id: string, valueDto: ValueDto, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutKeyNotes(id, valueDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Court} court 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutMatterCourt(id: string, court: Court, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutMatterCourt(id, court, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Resident} resident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutMatterResident(id: string, resident: Resident, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutMatterResident(id, resident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Counsel} counsel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutOpposingCounsel(id: string, counsel: Counsel, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutOpposingCounsel(id, counsel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ValueDto} valueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutRespAttorney(id: string, valueDto: ValueDto, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutRespAttorney(id, valueDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ResponsibleParty} responsibleParty 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterControllerPutResponsibleParty(id: string, responsibleParty: ResponsibleParty, options?: any): AxiosPromise<void> {
            return localVarFp.matterControllerPutResponsibleParty(id, responsibleParty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerGet(id: string, options?: any): AxiosPromise<Array<MatterTask>> {
            return localVarFp.matterTaskControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [facility] 
         * @param {any} [search] 
         * @param {any} [maxduedate] 
         * @param {any} [minduedate] 
         * @param {any} [assigneeid] 
         * @param {any} [pagesize] 
         * @param {any} [pagingkey] 
         * @param {any} [clientid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerIndex(facility?: any, search?: any, maxduedate?: any, minduedate?: any, assigneeid?: any, pagesize?: any, pagingkey?: any, clientid?: any, options?: any): AxiosPromise<MatterTaskSearchDto> {
            return localVarFp.matterTaskControllerIndex(facility, search, maxduedate, minduedate, assigneeid, pagesize, pagingkey, clientid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MatterTask} matterTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPut(id: string, matterTask: MatterTask, options?: any): AxiosPromise<void> {
            return localVarFp.matterTaskControllerPut(id, matterTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPutActive(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.matterTaskControllerPutActive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matterTaskControllerPutInactive(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.matterTaskControllerPutInactive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [active] 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {string} [membertype] 
         * @param {string} [memberid] 
         * @param {string} [pagingkey] 
         * @param {number} [pagesize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueUserControllerGetUsers(active?: string, username?: string, email?: string, membertype?: string, memberid?: string, pagingkey?: string, pagesize?: number, options?: any): AxiosPromise<void> {
            return localVarFp.revenueUserControllerGetUsers(active, username, email, membertype, memberid, pagingkey, pagesize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetService(options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerGetService(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetServiceNotes(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerGetServiceNotes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadControllerPost(options?: any): AxiosPromise<void> {
            return localVarFp.uploadControllerPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public attorneyControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).attorneyControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerAuthenticate(authCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerLogin(authCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerLogout(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthMfaDto} authMfaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerValidateMfaCode(authMfaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientControllerGetClient(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientControllerGetClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientControllerGetClientFacilities(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientControllerGetClientFacilities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientControllerGetClientUsers(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientControllerGetClientUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientControllerGetClients(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientControllerGetClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Client} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientControllerPutClient(id: string, client: Client, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientControllerPutClient(id, client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadControllerDownload(key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadControllerDownload(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilityControllerGetFacility(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilityControllerGetFacility(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilityControllerGetFacilitys(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilityControllerGetFacilitys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Facility} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilityControllerPutFacility(id: string, facility: Facility, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilityControllerPutFacility(id, facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerGetMatterDocuments(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerGetMatterDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [createdby] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerGetMatterNotes(id: string, createdby?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerGetMatterNotes(id, createdby, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerGetMatterSnapshot(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerGetMatterSnapshot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [matterstatus] 
     * @param {any} [matterid] 
     * @param {any} [creationstatus] 
     * @param {any} [creationcategory] 
     * @param {any} [createdby] 
     * @param {any} [search] 
     * @param {any} [pagesize] 
     * @param {any} [pagingkey] 
     * @param {any} [facilityid] 
     * @param {any} [clientid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerIndex(matterstatus?: any, matterid?: any, creationstatus?: any, creationcategory?: any, createdby?: any, search?: any, pagesize?: any, pagingkey?: any, facilityid?: any, clientid?: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerIndex(matterstatus, matterid, creationstatus, creationcategory, createdby, search, pagesize, pagingkey, facilityid, clientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Matter} matter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPostMatter(matter: Matter, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPostMatter(matter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Debt} debt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutDebt(id: string, debt: Debt, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutDebt(id, debt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ValueDto} valueDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutKeyNotes(id: string, valueDto: ValueDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutKeyNotes(id, valueDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Court} court 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutMatterCourt(id: string, court: Court, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutMatterCourt(id, court, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Resident} resident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutMatterResident(id: string, resident: Resident, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutMatterResident(id, resident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Counsel} counsel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutOpposingCounsel(id: string, counsel: Counsel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutOpposingCounsel(id, counsel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ValueDto} valueDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutRespAttorney(id: string, valueDto: ValueDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutRespAttorney(id, valueDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ResponsibleParty} responsibleParty 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterControllerPutResponsibleParty(id: string, responsibleParty: ResponsibleParty, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterControllerPutResponsibleParty(id, responsibleParty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterTaskControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterTaskControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [facility] 
     * @param {any} [search] 
     * @param {any} [maxduedate] 
     * @param {any} [minduedate] 
     * @param {any} [assigneeid] 
     * @param {any} [pagesize] 
     * @param {any} [pagingkey] 
     * @param {any} [clientid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterTaskControllerIndex(facility?: any, search?: any, maxduedate?: any, minduedate?: any, assigneeid?: any, pagesize?: any, pagingkey?: any, clientid?: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterTaskControllerIndex(facility, search, maxduedate, minduedate, assigneeid, pagesize, pagingkey, clientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MatterTask} matterTask 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterTaskControllerPut(id: string, matterTask: MatterTask, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterTaskControllerPut(id, matterTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterTaskControllerPutActive(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterTaskControllerPutActive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public matterTaskControllerPutInactive(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).matterTaskControllerPutInactive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [active] 
     * @param {string} [username] 
     * @param {string} [email] 
     * @param {string} [membertype] 
     * @param {string} [memberid] 
     * @param {string} [pagingkey] 
     * @param {number} [pagesize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public revenueUserControllerGetUsers(active?: string, username?: string, email?: string, membertype?: string, memberid?: string, pagingkey?: string, pagesize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).revenueUserControllerGetUsers(active, username, email, membertype, memberid, pagingkey, pagesize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public serviceControllerGetService(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).serviceControllerGetService(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public serviceControllerGetServiceNotes(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).serviceControllerGetServiceNotes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadControllerPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadControllerPost(options).then((request) => request(this.axios, this.basePath));
    }
}


