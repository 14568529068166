import { NgModule } from '@angular/core';
import { AppComponent } from './components/app/app.component';
import { accountApiProvider, MaterialModule, UiCommonModule } from '@evo/ui/common';

import { RoutingModule } from './routing.module';
import { environment } from '../environments/environment';
import { AppShellPageComponent } from './pages/app-shell-page/app-shell-page.component';
import { CartModalComponent } from './components/cart-modal/cart-modal.component';
import { OneAppPageComponent } from './pages/one-app-page/one-app-page.component';
import { AppButtonsComponent } from './components/app-buttons/app-buttons.component';
import { ProductComponent } from './components/product/product.component';
import { ProductModalComponent } from './components/product-modal/product-modal.component';
import { PriceAdmissionsComponent } from './components/price-admissions/price-admissions.component';
import { PriceComplianceComponent } from './components/price-compliance/price-compliance.component';
import { PaymentConfirmationPageComponent } from './pages/payment-confirmation-page/payment-confirmation-page.component';
import { CodeComponent } from './pages/code/code.component';
import { EmailComponent } from './pages/email/email.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { LoginComponent } from './pages/login/login.component';
import { MfaComponent } from './pages/mfa/mfa.component';
import { PasswordComponent } from './pages/password/password.component';
import { PhoneComponent } from './pages/phone/phone.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const components = [
  AppButtonsComponent,
  AppComponent,
  CartModalComponent,
  ProductComponent,
  ProductModalComponent,
];

const pages = [
  AppShellPageComponent,
  CodeComponent,
  EmailComponent,
  ForgotComponent,
  LoginComponent,
  MfaComponent,
  OneAppPageComponent,
  PasswordComponent,
  PaymentConfirmationPageComponent,
  PhoneComponent,
  PriceAdmissionsComponent,
  PriceComplianceComponent,
  ResetPasswordComponent,
  SignupComponent
];

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    UiCommonModule.configure(environment),
    MaterialModule,
    RoutingModule,
  ],
  providers: [
    accountApiProvider(environment),
  ],
  exports: pages,
  bootstrap: [AppComponent],
})
export class UiAccountModule {}

