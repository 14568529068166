<evo-app-bar>
  <div class="button-group row flex-end">
    <button class="warn" mat-mini-fab [matMenuTriggerFor]="menu">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="claims">
        <div class="mb-1 p-2" mat-list-item>
          <div class="mat-body-2">{{ claims.email }}</div>
          <div>{{ claims.phone }}</div>
        </div>
      </ng-container>
      <a mat-menu-item routerLink="/"
        ><mat-icon color="accent">apps</mat-icon> Apps</a
      >
      <a mat-menu-item [href]="uiUrlAdmin"
        ><mat-icon color="accent">settings</mat-icon> Configuration</a
      >
      <button mat-menu-item (click)="logout()">
        <mat-icon color="accent">logout</mat-icon> Sign out
      </button>
    </mat-menu>
  </div>
</evo-app-bar>

<evo-busy [busy]="busy$ | async">
  <div class="p-2 gray">
    <div class="mat-h1">Getting started</div>
    <evo-app-buttons
      [groups]="['DOC', 'COV']"
      (select)="open($event)"
    ></evo-app-buttons>
  </div>

  <div class="p-2 lite" *ngIf="!(busy$ | async) && apps?.length">
    <div class="mat-h1">Your apps</div>
    <evo-app-buttons
      [groups]="apps"
      (select)="navigate($event)"
    ></evo-app-buttons>
  </div>

  <div class="p-2 dark" *ngIf="!(busy$ | async) && available?.length">
    <div class="mat-h1">Available apps</div>
    <div class="mat-body-2 row space-between">
      <div>Click on an app to learn more</div>
      <div>
        <button
          class="card"
          mat-mini-fab
          color="accent"
          (click)="showCart()"
          *ngIf="(cartSvc.items$ | async)?.length"
        >
          <mat-icon>shopping_cart</mat-icon>
        </button>
      </div>
    </div>
    <evo-app-buttons
      [groups]="available"
      (select)="preview($event)"
    ></evo-app-buttons>
  </div>
</evo-busy>
