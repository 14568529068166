/* tslint:disable */
/* eslint-disable */
/**
 * ApiLawbotModule
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessTokenDto
 */
export interface AccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'sub': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'iss': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'event_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'token_use': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'scope': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'auth_time': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'exp': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'iat': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'jti': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    'username': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessTokenDto
     */
    'cognito:groups': Array<string>;
}
/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'name': string;
    /**
     * 
     * @type {AppData}
     * @memberof App
     */
    'data'?: AppData;
    /**
     * 
     * @type {Array<License>}
     * @memberof App
     */
    'licenses'?: Array<License>;
}
/**
 * 
 * @export
 * @interface AppData
 */
export interface AppData {
    /**
     * 
     * @type {object}
     * @memberof AppData
     */
    'roleSeedData'?: object;
    /**
     * 
     * @type {QuanityConfig}
     * @memberof AppData
     */
    'quantityConfig': QuanityConfig;
    /**
     * 
     * @type {boolean}
     * @memberof AppData
     */
    'canBePurchased'?: boolean;
}
/**
 * 
 * @export
 * @interface AskDto
 */
export interface AskDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AskDto
     */
    'lawbotCategoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AskDto
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface AuthCredentialsDto
 */
export interface AuthCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof AuthCredentialsDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AuthCredentialsDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AuthMfaDto
 */
export interface AuthMfaDto {
    /**
     * 
     * @type {string}
     * @memberof AuthMfaDto
     */
    'mfa': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMfaDto
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface AuthTokensDto
 */
export interface AuthTokensDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokensDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokensDto
     */
    'idToken': string;
}
/**
 * 
 * @export
 * @interface CartDto
 */
export interface CartDto {
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'facilityCount': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CartDto
     */
    'products': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CartDto
     */
    'promo': string;
}
/**
 * 
 * @export
 * @interface CheckoutSessionDto
 */
export interface CheckoutSessionDto {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface CodeDto
 */
export interface CodeDto {
    /**
     * 
     * @type {string}
     * @memberof CodeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ComplianceOrg
 */
export interface ComplianceOrg {
    /**
     * 
     * @type {string}
     * @memberof ComplianceOrg
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceOrg
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceOrg
     */
    'codeOfConductUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceOrg
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface ContactInfoDto
 */
export interface ContactInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDto
     */
    'orgId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfoDto
     */
    'isAnon'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAttachmentDto
 */
export interface CreateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'attachmentType': CreateAttachmentDtoAttachmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'appId': CreateAttachmentDtoAppIdEnum;
}

export const CreateAttachmentDtoAttachmentTypeEnum = {
    DoxamationBatch: 'DOXAMATION_BATCH',
    DoxamationInstance: 'DOXAMATION_INSTANCE',
    DoxamationTemplate: 'DOXAMATION_TEMPLATE',
    LawbotUpload: 'LAWBOT_UPLOAD',
    Message: 'MESSAGE',
    Note: 'NOTE',
    OrgLogo: 'ORG_LOGO',
    ReportRendering: 'REPORT_RENDERING',
    ReporterSubmission: 'REPORTER_SUBMISSION'
} as const;

export type CreateAttachmentDtoAttachmentTypeEnum = typeof CreateAttachmentDtoAttachmentTypeEnum[keyof typeof CreateAttachmentDtoAttachmentTypeEnum];
export const CreateAttachmentDtoAppIdEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type CreateAttachmentDtoAppIdEnum = typeof CreateAttachmentDtoAppIdEnum[keyof typeof CreateAttachmentDtoAppIdEnum];

/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDto
     */
    'isAnon'?: boolean;
}
/**
 * 
 * @export
 * @interface Fac
 */
export interface Fac {
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'zip'?: string;
    /**
     * 
     * @type {object}
     * @memberof Fac
     */
    'createdByApp': object;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'createdAt'?: string;
    /**
     * 
     * @type {object}
     * @memberof Fac
     */
    'updatedByApp'?: object;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fac
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof Fac
     */
    'org'?: Org;
}
/**
 * 
 * @export
 * @interface GetEntitiesDto
 */
export interface GetEntitiesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEntitiesDto
     */
    'entityIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GlobalAttachment
 */
export interface GlobalAttachment {
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'appId': GlobalAttachmentAppIdEnum;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'attachmentType': GlobalAttachmentAttachmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'userId'?: string;
    /**
     * 
     * @type {User}
     * @memberof GlobalAttachment
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof GlobalAttachment
     */
    'org': Org;
    /**
     * 
     * @type {string}
     * @memberof GlobalAttachment
     */
    'createdAt'?: string;
}

export const GlobalAttachmentAppIdEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type GlobalAttachmentAppIdEnum = typeof GlobalAttachmentAppIdEnum[keyof typeof GlobalAttachmentAppIdEnum];
export const GlobalAttachmentAttachmentTypeEnum = {
    DoxamationBatch: 'DOXAMATION_BATCH',
    DoxamationInstance: 'DOXAMATION_INSTANCE',
    DoxamationTemplate: 'DOXAMATION_TEMPLATE',
    LawbotUpload: 'LAWBOT_UPLOAD',
    Message: 'MESSAGE',
    Note: 'NOTE',
    OrgLogo: 'ORG_LOGO',
    ReportRendering: 'REPORT_RENDERING',
    ReporterSubmission: 'REPORTER_SUBMISSION'
} as const;

export type GlobalAttachmentAttachmentTypeEnum = typeof GlobalAttachmentAttachmentTypeEnum[keyof typeof GlobalAttachmentAttachmentTypeEnum];

/**
 * 
 * @export
 * @interface HealthDto
 */
export interface HealthDto {
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'free': number;
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof HealthDto
     */
    'percentUtilization': number;
    /**
     * 
     * @type {string}
     * @memberof HealthDto
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {Array<string>}
     * @memberof Invitation
     */
    'apps': Array<InvitationAppsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isAnon': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Invitation
     */
    'facs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Invitation
     */
    'reports': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Invitation
     */
    'categories': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'phoneValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'emailValid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'orgId'?: string;
    /**
     * 
     * @type {Org}
     * @memberof Invitation
     */
    'org'?: Org;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isCompOfficer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isCompReporter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isCompAssignee': boolean;
}

export const InvitationAppsEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type InvitationAppsEnum = typeof InvitationAppsEnum[keyof typeof InvitationAppsEnum];

/**
 * 
 * @export
 * @interface LawbotAnswer
 */
export interface LawbotAnswer {
    /**
     * 
     * @type {string}
     * @memberof LawbotAnswer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotAnswer
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotAnswer
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotAnswer
     */
    'elapsedTime': string;
}
/**
 * 
 * @export
 * @interface LawbotCategory
 */
export interface LawbotCategory {
    /**
     * 
     * @type {string}
     * @memberof LawbotCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotCategory
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof LawbotCategory
     */
    'org': Org;
    /**
     * 
     * @type {boolean}
     * @memberof LawbotCategory
     */
    'global': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LawbotCategory
     */
    'published': boolean;
    /**
     * 
     * @type {Array<LawbotUpload>}
     * @memberof LawbotCategory
     */
    'lawbotUploads'?: Array<LawbotUpload>;
}
/**
 * 
 * @export
 * @interface LawbotData
 */
export interface LawbotData {
    /**
     * 
     * @type {string}
     * @memberof LawbotData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotData
     */
    'text': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof LawbotData
     */
    'embedding': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof LawbotData
     */
    'lawbotUploadId': string;
    /**
     * 
     * @type {LawbotUpload}
     * @memberof LawbotData
     */
    'lawbotUpload': LawbotUpload;
}
/**
 * 
 * @export
 * @interface LawbotUpload
 */
export interface LawbotUpload {
    /**
     * 
     * @type {string}
     * @memberof LawbotUpload
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LawbotUpload
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof LawbotUpload
     */
    'org': Org;
    /**
     * 
     * @type {boolean}
     * @memberof LawbotUpload
     */
    'global': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LawbotUpload
     */
    'published': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LawbotUpload
     */
    'unprocessable': boolean;
    /**
     * 
     * @type {number}
     * @memberof LawbotUpload
     */
    'processed': number;
    /**
     * 
     * @type {Array<LawbotCategory>}
     * @memberof LawbotUpload
     */
    'lawbotCategories'?: Array<LawbotCategory>;
    /**
     * 
     * @type {string}
     * @memberof LawbotUpload
     */
    'attachmentId': string;
    /**
     * 
     * @type {GlobalAttachment}
     * @memberof LawbotUpload
     */
    'attachment': GlobalAttachment;
    /**
     * 
     * @type {Array<LawbotData>}
     * @memberof LawbotUpload
     */
    'lawbotDataRecords'?: Array<LawbotData>;
}
/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof License
     */
    'org': Org;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'app': LicenseAppEnum;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'endDate': string;
    /**
     * 
     * @type {object}
     * @memberof License
     */
    'stripePaymentId'?: object;
    /**
     * 
     * @type {StripePayment}
     * @memberof License
     */
    'stripePayment': StripePayment;
    /**
     * 
     * @type {boolean}
     * @memberof License
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'maxFacs': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'maxUsers': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'maxAdmins': number;
}

export const LicenseAppEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type LicenseAppEnum = typeof LicenseAppEnum[keyof typeof LicenseAppEnum];

/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'active': boolean;
    /**
     * 
     * @type {ComplianceOrg}
     * @memberof Org
     */
    'complianceOrg': ComplianceOrg;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'codeOfConductUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'zip'?: string;
    /**
     * 
     * @type {Array<Fac>}
     * @memberof Org
     */
    'facilities'?: Array<Fac>;
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof Org
     */
    'invitations'?: Array<Invitation>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Org
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<License>}
     * @memberof Org
     */
    'licenses'?: Array<License>;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'createdByApp': OrgCreatedByAppEnum;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'updatedByApp'?: OrgUpdatedByAppEnum;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxFacs'?: number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'maxAdmins'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'canAddFacs'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'canAddUsers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'canAddAdmins'?: boolean;
}

export const OrgCreatedByAppEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type OrgCreatedByAppEnum = typeof OrgCreatedByAppEnum[keyof typeof OrgCreatedByAppEnum];
export const OrgUpdatedByAppEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type OrgUpdatedByAppEnum = typeof OrgUpdatedByAppEnum[keyof typeof OrgUpdatedByAppEnum];

/**
 * 
 * @export
 * @interface PasswordDto
 */
export interface PasswordDto {
    /**
     * 
     * @type {string}
     * @memberof PasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordDto
     */
    'confirm': string;
}
/**
 * 
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'hash': string;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordReset
     */
    'emailValid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface PhoneDto
 */
export interface PhoneDto {
    /**
     * 
     * @type {string}
     * @memberof PhoneDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface PhoneValidDto
 */
export interface PhoneValidDto {
    /**
     * 
     * @type {boolean}
     * @memberof PhoneValidDto
     */
    'phoneValid': boolean;
}
/**
 * 
 * @export
 * @interface QuanityConfig
 */
export interface QuanityConfig {
    /**
     * 
     * @type {number}
     * @memberof QuanityConfig
     */
    'maxFacs': number;
    /**
     * 
     * @type {number}
     * @memberof QuanityConfig
     */
    'maxUsers': number;
    /**
     * 
     * @type {number}
     * @memberof QuanityConfig
     */
    'maxAdmins': number;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'relationship': string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isAnonymous'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'wasReported'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'reportedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'othersAware'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'knownWhere'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'describeWhere'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'knownWhen': boolean;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'describeWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'describeHow'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'howOther'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Report
     */
    'documents'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'facilityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdById'?: string;
    /**
     * 
     * @type {User}
     * @memberof Report
     */
    'createdBy'?: User;
    /**
     * 
     * @type {User}
     * @memberof Report
     */
    'assignedTo'?: User;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'assignedToId'?: string;
    /**
     * 
     * @type {Fac}
     * @memberof Report
     */
    'facility': Fac;
    /**
     * 
     * @type {Org}
     * @memberof Report
     */
    'organization': Org;
    /**
     * 
     * @type {ReportCategory}
     * @memberof Report
     */
    'reportCategory'?: ReportCategory;
    /**
     * 
     * @type {Array<ReportAssignment>}
     * @memberof Report
     */
    'reportAssignments'?: Array<ReportAssignment>;
    /**
     * 
     * @type {Array<GlobalAttachment>}
     * @memberof Report
     */
    'attachments'?: Array<GlobalAttachment>;
}
/**
 * 
 * @export
 * @interface ReportAssignment
 */
export interface ReportAssignment {
    /**
     * 
     * @type {string}
     * @memberof ReportAssignment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportAssignment
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ReportAssignment
     */
    'createdAt': string;
    /**
     * 
     * @type {Report}
     * @memberof ReportAssignment
     */
    'report': Report;
    /**
     * 
     * @type {string}
     * @memberof ReportAssignment
     */
    'reportId': string;
}
/**
 * 
 * @export
 * @interface ReportCategory
 */
export interface ReportCategory {
    /**
     * 
     * @type {number}
     * @memberof ReportCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReportCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCategory
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCategory
     */
    'active': boolean;
    /**
     * 
     * @type {Array<Report>}
     * @memberof ReportCategory
     */
    'reports': Array<Report>;
}
/**
 * 
 * @export
 * @interface StripePayment
 */
export interface StripePayment {
    /**
     * 
     * @type {string}
     * @memberof StripePayment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripePayment
     */
    'sessionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripePayment
     */
    'amountTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripePayment
     */
    'facilityCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StripePayment
     */
    'livemode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripePayment
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripePayment
     */
    'stripePromoId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StripePayment
     */
    'apps': Array<StripePaymentAppsEnum>;
    /**
     * 
     * @type {string}
     * @memberof StripePayment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<License>}
     * @memberof StripePayment
     */
    'licenses'?: Array<License>;
}

export const StripePaymentAppsEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type StripePaymentAppsEnum = typeof StripePaymentAppsEnum[keyof typeof StripePaymentAppsEnum];

/**
 * 
 * @export
 * @interface StripePromo
 */
export interface StripePromo {
    /**
     * 
     * @type {string}
     * @memberof StripePromo
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof StripePromo
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StripePromo
     */
    'apps': Array<StripePromoAppsEnum>;
    /**
     * 
     * @type {number}
     * @memberof StripePromo
     */
    'ratio'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripePromo
     */
    'createdAt'?: string;
}

export const StripePromoAppsEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type StripePromoAppsEnum = typeof StripePromoAppsEnum[keyof typeof StripePromoAppsEnum];

/**
 * 
 * @export
 * @interface StripeWebhookEvent
 */
export interface StripeWebhookEvent {
    /**
     * 
     * @type {string}
     * @memberof StripeWebhookEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StripeWebhookEvent
     */
    'apiVersion'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeWebhookEvent
     */
    'created': number;
    /**
     * 
     * @type {object}
     * @memberof StripeWebhookEvent
     */
    'data': object;
    /**
     * 
     * @type {boolean}
     * @memberof StripeWebhookEvent
     */
    'livemode': boolean;
    /**
     * 
     * @type {number}
     * @memberof StripeWebhookEvent
     */
    'pendingWebhooks'?: number;
    /**
     * 
     * @type {object}
     * @memberof StripeWebhookEvent
     */
    'request': object;
    /**
     * 
     * @type {string}
     * @memberof StripeWebhookEvent
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface SuccessDto
 */
export interface SuccessDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface UploadFileDto
 */
export interface UploadFileDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadFileDto
     */
    'lawbotCategoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UploadTextDto
 */
export interface UploadTextDto {
    /**
     * 
     * @type {string}
     * @memberof UploadTextDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UploadTextDto
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadTextDto
     */
    'lawbotCategoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UrlDto
 */
export interface UrlDto {
    /**
     * 
     * @type {string}
     * @memberof UrlDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'active': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'apps': Array<UserAppsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'facs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'categories': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'reports': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'data'?: object;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'orgId': string;
    /**
     * 
     * @type {Org}
     * @memberof User
     */
    'org': Org;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdByApp': UserCreatedByAppEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedByApp'?: UserUpdatedByAppEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isCompOfficer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isCompReporter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isCompAssignee': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isQuota': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isAnon': boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'complianceOrgId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isSysAdmin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'hasAllFacs': boolean;
}

export const UserAppsEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type UserAppsEnum = typeof UserAppsEnum[keyof typeof UserAppsEnum];
export const UserCreatedByAppEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type UserCreatedByAppEnum = typeof UserCreatedByAppEnum[keyof typeof UserCreatedByAppEnum];
export const UserUpdatedByAppEnum = {
    Acc: 'ACC',
    Adm: 'ADM',
    Admmic: 'ADMMIC',
    Admfed: 'ADMFED',
    Com: 'COM',
    Cov: 'COV',
    Doc: 'DOC',
    Dox: 'DOX',
    Dqa: 'DQA',
    Evo: 'EVO',
    Global: 'GLOBAL',
    Lawbot: 'LAWBOT',
    Ppl: 'PPL',
    Reports: 'REPORTS',
    Rev: 'REV',
    Vma: 'VMA'
} as const;

export type UserUpdatedByAppEnum = typeof UserUpdatedByAppEnum[keyof typeof UserUpdatedByAppEnum];

/**
 * 
 * @export
 * @interface UserClaimDto
 */
export interface UserClaimDto {
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'apps': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'facs': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'reports': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isCompAssignee': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'hasReportsOnly': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isAnon': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isCompOfficer': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isCompReporter': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isAdmin': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isQuota': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'isSysAdmin': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimDto
     */
    'sub': string;
}
/**
 * 
 * @export
 * @interface UserIdDto
 */
export interface UserIdDto {
    /**
     * 
     * @type {string}
     * @memberof UserIdDto
     */
    'userId': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerDelete: async (facId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facId' is not null or undefined
            assertParamExists('adminFacControllerDelete', 'facId', facId)
            const localVarPath = `/api/admin/fac/{facId}`
                .replace(`{${"facId"}}`, encodeURIComponent(String(facId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerGet: async (facId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facId' is not null or undefined
            assertParamExists('adminFacControllerGet', 'facId', facId)
            const localVarPath = `/api/admin/fac/{facId}`
                .replace(`{${"facId"}}`, encodeURIComponent(String(facId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/fac`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerPost: async (fac: Fac, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fac' is not null or undefined
            assertParamExists('adminFacControllerPost', 'fac', fac)
            const localVarPath = `/api/admin/fac`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fac, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facId 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerPut: async (facId: string, fac: Fac, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facId' is not null or undefined
            assertParamExists('adminFacControllerPut', 'facId', facId)
            // verify required parameter 'fac' is not null or undefined
            assertParamExists('adminFacControllerPut', 'fac', fac)
            const localVarPath = `/api/admin/fac/{facId}`
                .replace(`{${"facId"}}`, encodeURIComponent(String(facId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fac, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerDelete: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('adminInvitationControllerDelete', 'invitationId', invitationId)
            const localVarPath = `/api/admin/invitation/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminInvitationControllerGet', 'email', email)
            const localVarPath = `/api/admin/invitation/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Invitation} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerPost: async (invitation: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('adminInvitationControllerPost', 'invitation', invitation)
            const localVarPath = `/api/admin/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerDeactivate: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('adminOrgControllerDeactivate', 'orgId', orgId)
            const localVarPath = `/api/admin/org/{orgId}/deactivate`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerDelete: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('adminOrgControllerDelete', 'orgId', orgId)
            const localVarPath = `/api/admin/org/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerGet: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('adminOrgControllerGet', 'orgId', orgId)
            const localVarPath = `/api/admin/org/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerPost: async (org: Org, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'org' is not null or undefined
            assertParamExists('adminOrgControllerPost', 'org', org)
            const localVarPath = `/api/admin/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(org, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerPut: async (orgId: string, org: Org, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('adminOrgControllerPut', 'orgId', orgId)
            // verify required parameter 'org' is not null or undefined
            assertParamExists('adminOrgControllerPut', 'org', org)
            const localVarPath = `/api/admin/org/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(org, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportCategoryControllerGet: async (reportCategoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportCategoryId' is not null or undefined
            assertParamExists('adminReportCategoryControllerGet', 'reportCategoryId', reportCategoryId)
            const localVarPath = `/api/admin/report-category/{reportCategoryId}`
                .replace(`{${"reportCategoryId"}}`, encodeURIComponent(String(reportCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportCategoryControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/report-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserControllerDelete', 'userId', userId)
            const localVarPath = `/api/admin/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserControllerGet', 'userId', userId)
            const localVarPath = `/api/admin/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerPost: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('adminUserControllerPost', 'user', user)
            const localVarPath = `/api/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerPut: async (userId: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserControllerPut', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('adminUserControllerPut', 'user', user)
            const localVarPath = `/api/admin/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate: async (authCredentialsDto: AuthCredentialsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsDto' is not null or undefined
            assertParamExists('authControllerAuthenticate', 'authCredentialsDto', authCredentialsDto)
            const localVarPath = `/api/auth/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (authCredentialsDto: AuthCredentialsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsDto' is not null or undefined
            assertParamExists('authControllerLogin', 'authCredentialsDto', authCredentialsDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateMfaCode: async (authMfaDto: AuthMfaDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authMfaDto' is not null or undefined
            assertParamExists('authControllerValidateMfaCode', 'authMfaDto', authMfaDto)
            const localVarPath = `/api/auth/validate-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authMfaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalAttachmentsControllerDelete', 'id', id)
            const localVarPath = `/api/global-attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerGetAttachmentsByEntityId: async (entityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('globalAttachmentsControllerGetAttachmentsByEntityId', 'entityId', entityId)
            const localVarPath = `/api/global-attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetEntitiesDto} getEntitiesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerGetEntities: async (getEntitiesDto: GetEntitiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getEntitiesDto' is not null or undefined
            assertParamExists('globalAttachmentsControllerGetEntities', 'getEntitiesDto', getEntitiesDto)
            const localVarPath = `/api/global-attachments/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getEntitiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerPost: async (createAttachmentDto: CreateAttachmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAttachmentDto' is not null or undefined
            assertParamExists('globalAttachmentsControllerPost', 'createAttachmentDto', createAttachmentDto)
            const localVarPath = `/api/global-attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAttachmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalDownloadsControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalDownloadsControllerGet', 'id', id)
            const localVarPath = `/api/global-downloads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotAnswerControllerDelete', 'id', id)
            const localVarPath = `/api/lawbot-answer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotAnswerControllerGet', 'id', id)
            const localVarPath = `/api/lawbot-answer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lawbot-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotCategoryControllerDelete', 'id', id)
            const localVarPath = `/api/lawbot-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotCategoryControllerGet', 'id', id)
            const localVarPath = `/api/lawbot-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lawbot-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerPost: async (lawbotCategory: LawbotCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawbotCategory' is not null or undefined
            assertParamExists('lawbotCategoryControllerPost', 'lawbotCategory', lawbotCategory)
            const localVarPath = `/api/lawbot-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawbotCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerPut: async (id: string, lawbotCategory: LawbotCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotCategoryControllerPut', 'id', id)
            // verify required parameter 'lawbotCategory' is not null or undefined
            assertParamExists('lawbotCategoryControllerPut', 'lawbotCategory', lawbotCategory)
            const localVarPath = `/api/lawbot-category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawbotCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotDataControllerDelete', 'id', id)
            const localVarPath = `/api/lawbot-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotDataControllerGet', 'id', id)
            const localVarPath = `/api/lawbot-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lawbot-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AskDto} askDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotQuestionControllerAsk: async (askDto: AskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'askDto' is not null or undefined
            assertParamExists('lawbotQuestionControllerAsk', 'askDto', askDto)
            const localVarPath = `/api/lawbot-question/ask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotUploadControllerDelete', 'id', id)
            const localVarPath = `/api/lawbot-upload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerDeleteCategoryAssociation: async (id: string, categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotUploadControllerDeleteCategoryAssociation', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('lawbotUploadControllerDeleteCategoryAssociation', 'categoryId', categoryId)
            const localVarPath = `/api/lawbot-upload/{id}/category/{categoryId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotUploadControllerGet', 'id', id)
            const localVarPath = `/api/lawbot-upload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lawbot-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadFileDto} uploadFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPostFile: async (uploadFileDto: UploadFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFileDto' is not null or undefined
            assertParamExists('lawbotUploadControllerPostFile', 'uploadFileDto', uploadFileDto)
            const localVarPath = `/api/lawbot-upload/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadTextDto} uploadTextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPostText: async (uploadTextDto: UploadTextDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadTextDto' is not null or undefined
            assertParamExists('lawbotUploadControllerPostText', 'uploadTextDto', uploadTextDto)
            const localVarPath = `/api/lawbot-upload/text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadTextDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotUpload} lawbotUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPut: async (id: string, lawbotUpload: LawbotUpload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotUploadControllerPut', 'id', id)
            // verify required parameter 'lawbotUpload' is not null or undefined
            assertParamExists('lawbotUploadControllerPut', 'lawbotUpload', lawbotUpload)
            const localVarPath = `/api/lawbot-upload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawbotUpload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPutCategoryAssociation: async (id: string, categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lawbotUploadControllerPutCategoryAssociation', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('lawbotUploadControllerPutCategoryAssociation', 'categoryId', categoryId)
            const localVarPath = `/api/lawbot-upload/{id}/category/{categoryId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CartDto} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCheckoutSession: async (cartDto: CartDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartDto' is not null or undefined
            assertParamExists('stripeControllerCheckoutSession', 'cartDto', cartDto)
            const localVarPath = `/api/stripe/checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSuccess: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('stripeControllerSuccess', 'sessionId', sessionId)
            const localVarPath = `/api/stripe/success/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StripeWebhookEvent} stripeWebhookEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookControllerWebhook: async (stripeWebhookEvent: StripeWebhookEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeWebhookEvent' is not null or undefined
            assertParamExists('stripeWebhookControllerWebhook', 'stripeWebhookEvent', stripeWebhookEvent)
            const localVarPath = `/api/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeWebhookEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerDeactivate: async (orgId: string, licenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('systemLicenseControllerDeactivate', 'orgId', orgId)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('systemLicenseControllerDeactivate', 'licenseId', licenseId)
            const localVarPath = `/api/system/license/{licenseId}/deactivate`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerDelete: async (licenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('systemLicenseControllerDelete', 'licenseId', licenseId)
            const localVarPath = `/api/system/license/{licenseId}`
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerIndex: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('systemLicenseControllerIndex', 'orgId', orgId)
            const localVarPath = `/api/system/license`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerPost: async (license: License, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'license' is not null or undefined
            assertParamExists('systemLicenseControllerPost', 'license', license)
            const localVarPath = `/api/system/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(license, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerPut: async (licenseId: string, license: License, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('systemLicenseControllerPut', 'licenseId', licenseId)
            // verify required parameter 'license' is not null or undefined
            assertParamExists('systemLicenseControllerPut', 'license', license)
            const localVarPath = `/api/system/license/{licenseId}`
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(license, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerGet: async (stripePromo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripePromo' is not null or undefined
            assertParamExists('systemStripePromoControllerGet', 'stripePromo', stripePromo)
            const localVarPath = `/api/system/stripe-promo/{stripePromo}`
                .replace(`{${"stripePromo"}}`, encodeURIComponent(String(stripePromo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerIndex: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/stripe-promo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StripePromo} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerPost: async (stripePromo: StripePromo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripePromo' is not null or undefined
            assertParamExists('systemStripePromoControllerPost', 'stripePromo', stripePromo)
            const localVarPath = `/api/system/stripe-promo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripePromo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {StripePromo} stripePromo2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerPut: async (stripePromo: string, stripePromo2: StripePromo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripePromo' is not null or undefined
            assertParamExists('systemStripePromoControllerPut', 'stripePromo', stripePromo)
            // verify required parameter 'stripePromo2' is not null or undefined
            assertParamExists('systemStripePromoControllerPut', 'stripePromo2', stripePromo2)
            const localVarPath = `/api/system/stripe-promo/{stripePromo}`
                .replace(`{${"stripePromo"}}`, encodeURIComponent(String(stripePromo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripePromo2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserIdDto} userIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userClaimControllerPost: async (userIdDto: UserIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIdDto' is not null or undefined
            assertParamExists('userClaimControllerPost', 'userIdDto', userIdDto)
            const localVarPath = `/api/user-claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAccessToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLegacyGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerLegacyGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPut: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userControllerPut', 'user', user)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userInvitationControllerGet', 'hash', hash)
            const localVarPath = `/api/user/invitation/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPost: async (emailDto: EmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            assertParamExists('userInvitationControllerPost', 'emailDto', emailDto)
            const localVarPath = `/api/user/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactInfoDto} contactInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPostLinkless: async (contactInfoDto: ContactInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactInfoDto' is not null or undefined
            assertParamExists('userInvitationControllerPostLinkless', 'contactInfoDto', contactInfoDto)
            const localVarPath = `/api/user/invitation/linkless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutCode: async (hash: string, codeDto: CodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userInvitationControllerPutCode', 'hash', hash)
            // verify required parameter 'codeDto' is not null or undefined
            assertParamExists('userInvitationControllerPutCode', 'codeDto', codeDto)
            const localVarPath = `/api/user/invitation/{hash}/code`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(codeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutLinkless: async (hash: string, codeDto: CodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userInvitationControllerPutLinkless', 'hash', hash)
            // verify required parameter 'codeDto' is not null or undefined
            assertParamExists('userInvitationControllerPutLinkless', 'codeDto', codeDto)
            const localVarPath = `/api/user/invitation/{hash}/linkless`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(codeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutPassword: async (hash: string, passwordDto: PasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userInvitationControllerPutPassword', 'hash', hash)
            // verify required parameter 'passwordDto' is not null or undefined
            assertParamExists('userInvitationControllerPutPassword', 'passwordDto', passwordDto)
            const localVarPath = `/api/user/invitation/{hash}/password`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {PhoneDto} phoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutPhone: async (hash: string, phoneDto: PhoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userInvitationControllerPutPhone', 'hash', hash)
            // verify required parameter 'phoneDto' is not null or undefined
            assertParamExists('userInvitationControllerPutPhone', 'phoneDto', phoneDto)
            const localVarPath = `/api/user/invitation/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerResendEmail: async (emailDto: EmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            assertParamExists('userInvitationControllerResendEmail', 'emailDto', emailDto)
            const localVarPath = `/api/user/invitation/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userPasswordResetControllerGet', 'hash', hash)
            const localVarPath = `/api/user/password-reset/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerPost: async (emailDto: EmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            assertParamExists('userPasswordResetControllerPost', 'emailDto', emailDto)
            const localVarPath = `/api/user/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerPut: async (hash: string, passwordDto: PasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('userPasswordResetControllerPut', 'hash', hash)
            // verify required parameter 'passwordDto' is not null or undefined
            assertParamExists('userPasswordResetControllerPut', 'passwordDto', passwordDto)
            const localVarPath = `/api/user/password-reset/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSessionControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFacControllerDelete(facId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fac>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFacControllerDelete(facId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFacControllerGet(facId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fac>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFacControllerGet(facId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFacControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fac>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFacControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFacControllerPost(fac: Fac, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fac>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFacControllerPost(fac, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facId 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFacControllerPut(facId: string, fac: Fac, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fac>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFacControllerPut(facId, fac, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvitationControllerDelete(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvitationControllerDelete(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvitationControllerGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvitationControllerGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvitationControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invitation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvitationControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Invitation} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvitationControllerPost(invitation: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invitation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvitationControllerPost(invitation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerDeactivate(orgId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerDeactivate(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerDelete(orgId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerDelete(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerGet(orgId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerGet(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Org>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerPost(org: Org, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerPost(org, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrgControllerPut(orgId: string, org: Org, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrgControllerPut(orgId, org, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReportCategoryControllerGet(reportCategoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReportCategoryControllerGet(reportCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReportCategoryControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReportCategoryControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerPost(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerPut(userId: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerPut(userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<App>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAuthenticate(authCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokensDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokensDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerValidateMfaCode(authMfaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttachmentsControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalAttachmentsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttachmentsControllerGetAttachmentsByEntityId(entityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalAttachmentsControllerGetAttachmentsByEntityId(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetEntitiesDto} getEntitiesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttachmentsControllerGetEntities(getEntitiesDto: GetEntitiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalAttachmentsControllerGetEntities(getEntitiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttachmentsControllerPost(createAttachmentDto: CreateAttachmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalAttachmentsControllerPost(createAttachmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalDownloadsControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalDownloadsControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotAnswerControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotAnswerControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotAnswerControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotAnswerControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotAnswerControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawbotAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotAnswerControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotCategoryControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotCategoryControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotCategoryControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotCategoryControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotCategoryControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawbotCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotCategoryControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotCategoryControllerPost(lawbotCategory: LawbotCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotCategoryControllerPost(lawbotCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotCategoryControllerPut(id: string, lawbotCategory: LawbotCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotCategoryControllerPut(id, lawbotCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotDataControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotDataControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotDataControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotDataControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotDataControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawbotData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotDataControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AskDto} askDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotQuestionControllerAsk(askDto: AskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotQuestionControllerAsk(askDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerDeleteCategoryAssociation(id: string, categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerDeleteCategoryAssociation(id, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawbotUpload>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadFileDto} uploadFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerPostFile(uploadFileDto: UploadFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerPostFile(uploadFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadTextDto} uploadTextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerPostText(uploadTextDto: UploadTextDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LawbotUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerPostText(uploadTextDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotUpload} lawbotUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerPut(id: string, lawbotUpload: LawbotUpload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerPut(id, lawbotUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lawbotUploadControllerPutCategoryAssociation(id: string, categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lawbotUploadControllerPutCategoryAssociation(id, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CartDto} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCheckoutSession(cartDto: CartDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCheckoutSession(cartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerSuccess(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerSuccess(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StripeWebhookEvent} stripeWebhookEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhookControllerWebhook(stripeWebhookEvent: StripeWebhookEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeWebhookControllerWebhook(stripeWebhookEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLicenseControllerDeactivate(orgId: string, licenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLicenseControllerDeactivate(orgId, licenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLicenseControllerDelete(licenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLicenseControllerDelete(licenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLicenseControllerIndex(orgId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<License>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLicenseControllerIndex(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLicenseControllerPost(license: License, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLicenseControllerPost(license, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLicenseControllerPut(licenseId: string, license: License, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLicenseControllerPut(licenseId, license, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemStripePromoControllerGet(stripePromo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripePromo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemStripePromoControllerGet(stripePromo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemStripePromoControllerIndex(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StripePromo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemStripePromoControllerIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StripePromo} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemStripePromoControllerPost(stripePromo: StripePromo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StripePromo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemStripePromoControllerPost(stripePromo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {StripePromo} stripePromo2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemStripePromoControllerPut(stripePromo: string, stripePromo2: StripePromo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StripePromo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemStripePromoControllerPut(stripePromo, stripePromo2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserIdDto} userIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userClaimControllerPost(userIdDto: UserIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userClaimControllerPost(userIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAccessToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAccessToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLegacyGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLegacyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerPut(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerPut(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerGet(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerGet(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPost(emailDto: EmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPost(emailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactInfoDto} contactInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPostLinkless(contactInfoDto: ContactInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPostLinkless(contactInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPutCode(hash: string, codeDto: CodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneValidDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPutCode(hash, codeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPutLinkless(hash: string, codeDto: CodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPutLinkless(hash, codeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPutPassword(hash: string, passwordDto: PasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPutPassword(hash, passwordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {PhoneDto} phoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerPutPhone(hash: string, phoneDto: PhoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerPutPhone(hash, phoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationControllerResendEmail(emailDto: EmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationControllerResendEmail(emailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPasswordResetControllerGet(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPasswordResetControllerGet(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPasswordResetControllerPost(emailDto: EmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPasswordResetControllerPost(emailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPasswordResetControllerPut(hash: string, passwordDto: PasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPasswordResetControllerPut(hash, passwordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSessionControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSessionControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerDelete(facId: string, options?: any): AxiosPromise<Array<Fac>> {
            return localVarFp.adminFacControllerDelete(facId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} facId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerGet(facId: string, options?: any): AxiosPromise<Fac> {
            return localVarFp.adminFacControllerGet(facId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerIndex(options?: any): AxiosPromise<Array<Fac>> {
            return localVarFp.adminFacControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerPost(fac: Fac, options?: any): AxiosPromise<Array<Fac>> {
            return localVarFp.adminFacControllerPost(fac, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} facId 
         * @param {Fac} fac 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFacControllerPut(facId: string, fac: Fac, options?: any): AxiosPromise<Array<Fac>> {
            return localVarFp.adminFacControllerPut(facId, fac, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerDelete(invitationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminInvitationControllerDelete(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerGet(email: string, options?: any): AxiosPromise<Invitation> {
            return localVarFp.adminInvitationControllerGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerIndex(options?: any): AxiosPromise<Array<Invitation>> {
            return localVarFp.adminInvitationControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Invitation} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitationControllerPost(invitation: Invitation, options?: any): AxiosPromise<Array<Invitation>> {
            return localVarFp.adminInvitationControllerPost(invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerDeactivate(orgId: string, options?: any): AxiosPromise<Org> {
            return localVarFp.adminOrgControllerDeactivate(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerDelete(orgId: string, options?: any): AxiosPromise<SuccessDto> {
            return localVarFp.adminOrgControllerDelete(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerGet(orgId: string, options?: any): AxiosPromise<Org> {
            return localVarFp.adminOrgControllerGet(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerIndex(options?: any): AxiosPromise<Array<Org>> {
            return localVarFp.adminOrgControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerPost(org: Org, options?: any): AxiosPromise<Org> {
            return localVarFp.adminOrgControllerPost(org, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {Org} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrgControllerPut(orgId: string, org: Org, options?: any): AxiosPromise<Org> {
            return localVarFp.adminOrgControllerPut(orgId, org, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportCategoryControllerGet(reportCategoryId: string, options?: any): AxiosPromise<ReportCategory> {
            return localVarFp.adminReportCategoryControllerGet(reportCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReportCategoryControllerIndex(options?: any): AxiosPromise<Array<ReportCategory>> {
            return localVarFp.adminReportCategoryControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerDelete(userId: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGet(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerIndex(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerPost(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerPost(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerPut(userId: string, user: User, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerPut(userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerIndex(options?: any): AxiosPromise<Array<App>> {
            return localVarFp.appControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerAuthenticate(authCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: any): AxiosPromise<AuthTokensDto> {
            return localVarFp.authControllerLogin(authCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthMfaDto} authMfaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: any): AxiosPromise<AuthTokensDto> {
            return localVarFp.authControllerValidateMfaCode(authMfaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.globalAttachmentsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerGetAttachmentsByEntityId(entityId: string, options?: any): AxiosPromise<Array<GlobalAttachment>> {
            return localVarFp.globalAttachmentsControllerGetAttachmentsByEntityId(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetEntitiesDto} getEntitiesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerGetEntities(getEntitiesDto: GetEntitiesDto, options?: any): AxiosPromise<Array<GlobalAttachment>> {
            return localVarFp.globalAttachmentsControllerGetEntities(getEntitiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttachmentsControllerPost(createAttachmentDto: CreateAttachmentDto, options?: any): AxiosPromise<Array<GlobalAttachment>> {
            return localVarFp.globalAttachmentsControllerPost(createAttachmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalDownloadsControllerGet(id: string, options?: any): AxiosPromise<UrlDto> {
            return localVarFp.globalDownloadsControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGet(options?: any): AxiosPromise<HealthDto> {
            return localVarFp.healthControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerDelete(id: string, options?: any): AxiosPromise<LawbotAnswer> {
            return localVarFp.lawbotAnswerControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerGet(id: string, options?: any): AxiosPromise<LawbotAnswer> {
            return localVarFp.lawbotAnswerControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotAnswerControllerIndex(options?: any): AxiosPromise<Array<LawbotAnswer>> {
            return localVarFp.lawbotAnswerControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerDelete(id: string, options?: any): AxiosPromise<LawbotCategory> {
            return localVarFp.lawbotCategoryControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerGet(id: string, options?: any): AxiosPromise<LawbotCategory> {
            return localVarFp.lawbotCategoryControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerIndex(options?: any): AxiosPromise<Array<LawbotCategory>> {
            return localVarFp.lawbotCategoryControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerPost(lawbotCategory: LawbotCategory, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotCategoryControllerPost(lawbotCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotCategory} lawbotCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotCategoryControllerPut(id: string, lawbotCategory: LawbotCategory, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotCategoryControllerPut(id, lawbotCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerDelete(id: string, options?: any): AxiosPromise<LawbotData> {
            return localVarFp.lawbotDataControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerGet(id: string, options?: any): AxiosPromise<LawbotData> {
            return localVarFp.lawbotDataControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotDataControllerIndex(options?: any): AxiosPromise<Array<LawbotData>> {
            return localVarFp.lawbotDataControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AskDto} askDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotQuestionControllerAsk(askDto: AskDto, options?: any): AxiosPromise<LawbotAnswer> {
            return localVarFp.lawbotQuestionControllerAsk(askDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotUploadControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerDeleteCategoryAssociation(id: string, categoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotUploadControllerDeleteCategoryAssociation(id, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerGet(id: string, options?: any): AxiosPromise<LawbotUpload> {
            return localVarFp.lawbotUploadControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerIndex(options?: any): AxiosPromise<Array<LawbotUpload>> {
            return localVarFp.lawbotUploadControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadFileDto} uploadFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPostFile(uploadFileDto: UploadFileDto, options?: any): AxiosPromise<LawbotUpload> {
            return localVarFp.lawbotUploadControllerPostFile(uploadFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadTextDto} uploadTextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPostText(uploadTextDto: UploadTextDto, options?: any): AxiosPromise<LawbotUpload> {
            return localVarFp.lawbotUploadControllerPostText(uploadTextDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {LawbotUpload} lawbotUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPut(id: string, lawbotUpload: LawbotUpload, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotUploadControllerPut(id, lawbotUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lawbotUploadControllerPutCategoryAssociation(id: string, categoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.lawbotUploadControllerPutCategoryAssociation(id, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartDto} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCheckoutSession(cartDto: CartDto, options?: any): AxiosPromise<CheckoutSessionDto> {
            return localVarFp.stripeControllerCheckoutSession(cartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSuccess(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.stripeControllerSuccess(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StripeWebhookEvent} stripeWebhookEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookControllerWebhook(stripeWebhookEvent: StripeWebhookEvent, options?: any): AxiosPromise<void> {
            return localVarFp.stripeWebhookControllerWebhook(stripeWebhookEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerDeactivate(orgId: string, licenseId: string, options?: any): AxiosPromise<License> {
            return localVarFp.systemLicenseControllerDeactivate(orgId, licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerDelete(licenseId: string, options?: any): AxiosPromise<SuccessDto> {
            return localVarFp.systemLicenseControllerDelete(licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerIndex(orgId: string, options?: any): AxiosPromise<Array<License>> {
            return localVarFp.systemLicenseControllerIndex(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerPost(license: License, options?: any): AxiosPromise<License> {
            return localVarFp.systemLicenseControllerPost(license, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} licenseId 
         * @param {License} license 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLicenseControllerPut(licenseId: string, license: License, options?: any): AxiosPromise<License> {
            return localVarFp.systemLicenseControllerPut(licenseId, license, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerGet(stripePromo: string, options?: any): AxiosPromise<StripePromo> {
            return localVarFp.systemStripePromoControllerGet(stripePromo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerIndex(options?: any): AxiosPromise<Array<StripePromo>> {
            return localVarFp.systemStripePromoControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StripePromo} stripePromo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerPost(stripePromo: StripePromo, options?: any): AxiosPromise<Array<StripePromo>> {
            return localVarFp.systemStripePromoControllerPost(stripePromo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripePromo 
         * @param {StripePromo} stripePromo2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStripePromoControllerPut(stripePromo: string, stripePromo2: StripePromo, options?: any): AxiosPromise<Array<StripePromo>> {
            return localVarFp.systemStripePromoControllerPut(stripePromo, stripePromo2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserIdDto} userIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userClaimControllerPost(userIdDto: UserIdDto, options?: any): AxiosPromise<UserClaimDto> {
            return localVarFp.userClaimControllerPost(userIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet(options?: any): AxiosPromise<User> {
            return localVarFp.userControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAccessToken(options?: any): AxiosPromise<AccessTokenDto> {
            return localVarFp.userControllerGetAccessToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCategories(options?: any): AxiosPromise<Array<ReportCategory>> {
            return localVarFp.userControllerGetUserCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLegacyGet(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerLegacyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPut(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerPut(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerGet(hash: string, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPost(emailDto: EmailDto, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerPost(emailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactInfoDto} contactInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPostLinkless(contactInfoDto: ContactInfoDto, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerPostLinkless(contactInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutCode(hash: string, codeDto: CodeDto, options?: any): AxiosPromise<PhoneValidDto> {
            return localVarFp.userInvitationControllerPutCode(hash, codeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {CodeDto} codeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutLinkless(hash: string, codeDto: CodeDto, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerPutLinkless(hash, codeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutPassword(hash: string, passwordDto: PasswordDto, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerPutPassword(hash, passwordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {PhoneDto} phoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerPutPhone(hash: string, phoneDto: PhoneDto, options?: any): AxiosPromise<Invitation> {
            return localVarFp.userInvitationControllerPutPhone(hash, phoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationControllerResendEmail(emailDto: EmailDto, options?: any): AxiosPromise<void> {
            return localVarFp.userInvitationControllerResendEmail(emailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerGet(hash: string, options?: any): AxiosPromise<PasswordReset> {
            return localVarFp.userPasswordResetControllerGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerPost(emailDto: EmailDto, options?: any): AxiosPromise<PasswordReset> {
            return localVarFp.userPasswordResetControllerPost(emailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {PasswordDto} passwordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordResetControllerPut(hash: string, passwordDto: PasswordDto, options?: any): AxiosPromise<PasswordReset> {
            return localVarFp.userPasswordResetControllerPut(hash, passwordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSessionControllerGet(options?: any): AxiosPromise<UserIdDto> {
            return localVarFp.userSessionControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} facId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminFacControllerDelete(facId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminFacControllerDelete(facId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} facId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminFacControllerGet(facId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminFacControllerGet(facId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminFacControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminFacControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Fac} fac 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminFacControllerPost(fac: Fac, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminFacControllerPost(fac, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} facId 
     * @param {Fac} fac 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminFacControllerPut(facId: string, fac: Fac, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminFacControllerPut(facId, fac, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInvitationControllerDelete(invitationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInvitationControllerDelete(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInvitationControllerGet(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInvitationControllerGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInvitationControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInvitationControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Invitation} invitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInvitationControllerPost(invitation: Invitation, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInvitationControllerPost(invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerDeactivate(orgId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerDeactivate(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerDelete(orgId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerDelete(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerGet(orgId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerGet(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Org} org 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerPost(org: Org, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerPost(org, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {Org} org 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminOrgControllerPut(orgId: string, org: Org, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminOrgControllerPut(orgId, org, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportCategoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminReportCategoryControllerGet(reportCategoryId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminReportCategoryControllerGet(reportCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminReportCategoryControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminReportCategoryControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUserControllerDelete(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUserControllerDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUserControllerGet(userId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUserControllerGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUserControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUserControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUserControllerPost(user: User, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUserControllerPost(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUserControllerPut(userId: string, user: User, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUserControllerPut(userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerAuthenticate(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerAuthenticate(authCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerLogin(authCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerLogout(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthMfaDto} authMfaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerValidateMfaCode(authMfaDto: AuthMfaDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerValidateMfaCode(authMfaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalAttachmentsControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).globalAttachmentsControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalAttachmentsControllerGetAttachmentsByEntityId(entityId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).globalAttachmentsControllerGetAttachmentsByEntityId(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetEntitiesDto} getEntitiesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalAttachmentsControllerGetEntities(getEntitiesDto: GetEntitiesDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).globalAttachmentsControllerGetEntities(getEntitiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAttachmentDto} createAttachmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalAttachmentsControllerPost(createAttachmentDto: CreateAttachmentDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).globalAttachmentsControllerPost(createAttachmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalDownloadsControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).globalDownloadsControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotAnswerControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotAnswerControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotAnswerControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotAnswerControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotAnswerControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotAnswerControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotCategoryControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotCategoryControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotCategoryControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotCategoryControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotCategoryControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotCategoryControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LawbotCategory} lawbotCategory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotCategoryControllerPost(lawbotCategory: LawbotCategory, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotCategoryControllerPost(lawbotCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {LawbotCategory} lawbotCategory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotCategoryControllerPut(id: string, lawbotCategory: LawbotCategory, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotCategoryControllerPut(id, lawbotCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotDataControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotDataControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotDataControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotDataControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotDataControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotDataControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AskDto} askDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotQuestionControllerAsk(askDto: AskDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotQuestionControllerAsk(askDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerDeleteCategoryAssociation(id: string, categoryId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerDeleteCategoryAssociation(id, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadFileDto} uploadFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerPostFile(uploadFileDto: UploadFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerPostFile(uploadFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadTextDto} uploadTextDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerPostText(uploadTextDto: UploadTextDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerPostText(uploadTextDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {LawbotUpload} lawbotUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerPut(id: string, lawbotUpload: LawbotUpload, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerPut(id, lawbotUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lawbotUploadControllerPutCategoryAssociation(id: string, categoryId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lawbotUploadControllerPutCategoryAssociation(id, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartDto} cartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stripeControllerCheckoutSession(cartDto: CartDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stripeControllerCheckoutSession(cartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stripeControllerSuccess(sessionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stripeControllerSuccess(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StripeWebhookEvent} stripeWebhookEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stripeWebhookControllerWebhook(stripeWebhookEvent: StripeWebhookEvent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stripeWebhookControllerWebhook(stripeWebhookEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemLicenseControllerDeactivate(orgId: string, licenseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemLicenseControllerDeactivate(orgId, licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemLicenseControllerDelete(licenseId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemLicenseControllerDelete(licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemLicenseControllerIndex(orgId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemLicenseControllerIndex(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {License} license 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemLicenseControllerPost(license: License, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemLicenseControllerPost(license, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} licenseId 
     * @param {License} license 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemLicenseControllerPut(licenseId: string, license: License, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemLicenseControllerPut(licenseId, license, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripePromo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemStripePromoControllerGet(stripePromo: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemStripePromoControllerGet(stripePromo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemStripePromoControllerIndex(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemStripePromoControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StripePromo} stripePromo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemStripePromoControllerPost(stripePromo: StripePromo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemStripePromoControllerPost(stripePromo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripePromo 
     * @param {StripePromo} stripePromo2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemStripePromoControllerPut(stripePromo: string, stripePromo2: StripePromo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemStripePromoControllerPut(stripePromo, stripePromo2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserIdDto} userIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userClaimControllerPost(userIdDto: UserIdDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userClaimControllerPost(userIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetAccessToken(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetAccessToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetUserCategories(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetUserCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerLegacyGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerLegacyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerPut(user: User, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerPut(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerGet(hash: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerGet(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailDto} emailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPost(emailDto: EmailDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPost(emailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactInfoDto} contactInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPostLinkless(contactInfoDto: ContactInfoDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPostLinkless(contactInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {CodeDto} codeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPutCode(hash: string, codeDto: CodeDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPutCode(hash, codeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {CodeDto} codeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPutLinkless(hash: string, codeDto: CodeDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPutLinkless(hash, codeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {PasswordDto} passwordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPutPassword(hash: string, passwordDto: PasswordDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPutPassword(hash, passwordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {PhoneDto} phoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerPutPhone(hash: string, phoneDto: PhoneDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerPutPhone(hash, phoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailDto} emailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInvitationControllerResendEmail(emailDto: EmailDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInvitationControllerResendEmail(emailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userPasswordResetControllerGet(hash: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userPasswordResetControllerGet(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailDto} emailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userPasswordResetControllerPost(emailDto: EmailDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userPasswordResetControllerPost(emailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {PasswordDto} passwordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userPasswordResetControllerPut(hash: string, passwordDto: PasswordDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userPasswordResetControllerPut(hash, passwordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSessionControllerGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSessionControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


