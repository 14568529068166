<evo-modal-page>
  <h1 class="text-center" *ngIf="emailSent">
    An invitation email has been sent to {{ email }}
  </h1>
  <h1 class="text-center" *ngIf="!emailSent">
    Enter your email to create an account
  </h1>
  <form [formGroup]="formGroup" *ngIf="!emailSent">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>email</mat-icon>
      <input
        matInput
        type="email"
        formControlName="email"
        placeholder="Email"
      />
    </mat-form-field>
    <evo-error name="email" [form]="formGroup"></evo-error>

    <button
      color="primary"
      class="xl"
      (click)="signup(formGroup.value)"
      [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine"
      mat-raised-button
      *ngIf="!(busy$ | async)"
    >
      Sign Up
    </button>
    <div *ngIf="busy$ | async" class="row">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
  </form>
</evo-modal-page>
