<evo-modal-page>
  <ng-container *ngIf="!complete">
    <h1 class="text-center">
      Create your account password
    </h1>
    <form class="mt-2" [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="password"
          placeholder="Password"
        />
      </mat-form-field>
      <evo-error name="password" [form]="formGroup"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="confirm"
          placeholder="Confirm"
        />
      </mat-form-field>
      <evo-error name="confirm" [form]="formGroup"></evo-error>

      <button
        color="primary"
        class="xl"
        (click)="resetPassword(formGroup.value)"
        [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine"
        mat-raised-button
        *ngIf="!(busy$ | async)"
      >
        Reset Password
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="complete">
    <h1 class="text-center">Your password has been changed.</h1>

    <a mat-raised-button routerLink="/login" class="xl width-full" color="primary"
      >Login</a
    >
  </ng-container>
</evo-modal-page>
