import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EvoCookieService,
} from '@evo/ui/common';
import {
  BehaviorSubject,
  concatMap,
  filter,
  from,
  map,
  tap,
} from 'rxjs';
import { CartService } from '../../services/cart.service';
import { AccessTokenDto, DefaultApi } from '@evo/generated/account';

@Component({
  templateUrl: './payment-confirmation-page.component.html',
  styleUrls: ['./payment-confirmation-page.component.scss'],
})
export class PaymentConfirmationPageComponent implements OnInit {
  session$ = this.route.params.pipe(
    map((params) => params['session']),
    filter(Boolean)
  );

  // i have no idea if the code below is needed or if it was just something i was testing and forgot about!
  // todo: determine if payment confirmation worx w/o this (which it should) and remove
  user$$ = new BehaviorSubject<AccessTokenDto>({} as any);
  data$ = from(this.api.userControllerGetAccessToken())
    .pipe(tap(({data}) => this.user$$.next(data)));

  constructor(
    private route: ActivatedRoute,
    private api: DefaultApi,
    private cookieService: EvoCookieService,
    public cartSvc: CartService,
  ) {}

  async ngOnInit() {
    this.session$
      .pipe(
        concatMap((session) =>
          this.api.stripeControllerSuccess(session)
        )
      )
      .subscribe();
  }

  async done() {
    try {
      try {
        this.cookieService.deleteSessionTokens();
        window.location.href = window.location.href;
      } catch (error: any) {
        console.warn(error);
      }
    } catch (error: any) {
      console.warn(error);
    }
  }
}
