<mat-toolbar class="lite row space-between center mat-elevation-z8">
  <div class="row header">
    <div>
      <evo-logo></evo-logo>
    </div>
    <div class="spacer"></div>
  </div>
  <div></div>
</mat-toolbar>
<div class="p-4">
  <h1>Payment was successful!</h1>
  <h2>You should receive an email with a confirmation code. Thank you!</h2>
  <h2>Please logout and log back in.</h2>
  <button class="logout" mat-raised-button color="primary" (click)="done()">
    Back to Login
  </button>
</div>
<div *ngIf="data$ | async"></div>
