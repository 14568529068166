import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseComponent,
  ClaimService,
  EvoCookieService,
  SnackService,
} from '@evo/ui/common';
import { MatDialog } from '@angular/material/dialog';
import { CartModalComponent } from '../../components/cart-modal/cart-modal.component';
import { CartService } from '../../services/cart.service';
import { ProductModalComponent } from '../../components/product-modal/product-modal.component';
import { environment } from '../../../environments/environment';
import { DefaultApi, User } from '@evo/generated/account';
import { AppEnum, appsForPurchase, unavailableApps } from '@evo/iso/common';
import { IParsedClaims } from '@evo/iso/common';

@Component({
  templateUrl: './one-app-page.component.html',
})
export class OneAppPageComponent extends BaseComponent implements OnInit {
  uiUrlAdmin = environment.uiUrlAdmin;
  apps: AppEnum[];
  available: AppEnum[];
  profileUrl = `${environment.uiUrlAdmin}/user-profile`;
  adminUrl = `${environment.uiUrlAdmin}/user-profile`;
  appsUrl = environment.uiUrlAccount;
  claims: IParsedClaims;

  constructor(
    private router: Router,
    private cookieService: EvoCookieService,
    private dialog: MatDialog,
    public cartSvc: CartService,
    private api: DefaultApi,
    public claimsSvc: ClaimService,
    public snackBar: SnackService
  ) {
    super();
    this.busy$$.next(true);
  }

  async ngOnInit() {
    this.claims = this.claimsSvc.get();
    this.apps = (this.claims.apps || []).filter(
      (sub: any) => !unavailableApps.includes(sub)
    ) as any;
    this.available = appsForPurchase.filter(
      (app: string) => !this.apps.includes(app as any)
    );
    if (this.apps.includes(AppEnum.COM)) {
      this.apps.push(AppEnum.REPORTS);
    }

    const current = window.location.href;

    if (this.claims.isSysAdmin) {
      this.apps.push(AppEnum.LAWBOT);

      if (current.includes('account-staging')) {
        this.apps.push(AppEnum.VMA);
        this.apps.push(AppEnum.DOX);
      }
    }

    this.busy$$.next(false);
  }

  navigate({ url }: { url: string }) {
    const current = window.location.href;
    if (current.includes('account-staging')) {
      const evoLegal = '.evo-legal.com';
      const evolve = '.evolve-legal.com';
      const evo = `-staging${evoLegal}`;
      if (url.includes(`dox${evolve}`)) {
        window.location.href = url.replace(`dox${evolve}`, `dox${evo}`);
      } else if (url.includes(`ai${evolve}`)) {
        window.location.href = url.replace(`ai${evolve}`, `ai${evo}`);
      } else if (url.includes(`vma${evolve}`)) {
        window.location.href = url.replace(`vma${evolve}`, `vma${evo}`);
      } else if (url.includes(`reports${evolve}`)) {
        window.location.href = url.replace(`reports${evolve}`, `reports${evo}`);
      } else if (url.includes(`compliance`)) {
        window.location.href = url;
      } else {
        this.snackBar.open(`There is no staging version of "${url}"!`);
      }
    } else {
      window.location.href = url;
    }
  }

  open({ url }: { url: string }) {
    window.open(url, '_blank');
  }

  showCart() {
    this.dialog.open(CartModalComponent);
  }

  preview(data: any) {
    this.dialog.open(ProductModalComponent, {
      data,
      width: '80%',
      maxWidth: '880px',
    });
  }

  async logout() {
    try {
      await this.api.authControllerLogout();
      this.cookieService.deleteSessionTokens();
      this.router.navigate(['login']);
    } catch (error: any) {
      console.warn(error);
    }
  }
}
