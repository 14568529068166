<evo-modal-page>
  <ng-container *ngIf="!invitation">
    <h1 class="text-center">
      Create your account password
    </h1>
    <form class="mt-2" [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="password"
          placeholder="Password"
        />
      </mat-form-field>
      <evo-error name="password" [form]="formGroup"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="confirm"
          placeholder="Confirm"
        />
      </mat-form-field>
      <evo-error name="confirm" [form]="formGroup"></evo-error>

      <div class="pb-2">
        <mat-checkbox color="primary" formControlName="accept">
          <mat-label>I have read and accept the <a target="_blank" href="https://www.evolve-legal.com/terms">
              Terms and Conditions
            </a></mat-label>
        </mat-checkbox>
      </div>
      <evo-error name="accept" [form]="formGroup"></evo-error>

      <button
        color="primary"
        class="xl"
        (click)="savePassword(formGroup.value)"
        [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine"
        mat-raised-button
        *ngIf="!(busy$ | async)"
      >
        Finish Account Setup
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="invitation">
    <h1 class="text-center">Your account setup has been completed!</h1>
    <h2 class="text-center">{{ invitation.email }}</h2>

    <a mat-raised-button routerLink="/login" class="xl width-full" color="primary"
      >Login</a
    >
  </ng-container>
</evo-modal-page>
