import { Injectable } from '@angular/core';
import { EvoCookieService } from './evo-cookie.service';
import { IParsedClaims, parseClaims } from '@evo/iso/common';
import { Observable, shareReplay, startWith } from 'rxjs';
import { decode } from '../functions/decode.function';
import { RedirectService } from './redirect.service';

@Injectable({ providedIn: 'root' })
export class ClaimService {
  constructor(private cookieService: EvoCookieService, private redirectSvc: RedirectService) {}

  get$ = new Observable<any>().pipe(startWith(this.get()), shareReplay());

  get(): IParsedClaims {
    const idToken = this.cookieService.getIdToken();

    const decoded = idToken ? decode(idToken) : undefined;

    if(!decoded || (decoded.exp * 1000 < +new Date())) {
      this.cookieService.deleteSessionTokens();
      window.location.href = this.redirectSvc.getLoginRedirectUrl();
    }

    return parseClaims(decoded);
  }
}
