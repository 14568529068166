import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CartService } from '../../services/cart.service';
import { CartModalComponent } from '../cart-modal/cart-modal.component';

@Component({
  templateUrl: './product-modal.component.html',
})
export class ProductModalComponent {
  constructor(
    public cartSvc: CartService,
    @Inject(MAT_DIALOG_DATA) public product: any,
    public dialogRef: MatDialogRef<any>,
    private dialog: MatDialog
  ) {}

  goToCart() {
    this.dialogRef.close();
    this.dialog.open(CartModalComponent, { width: '80%', maxWidth: '880px' });
  }
}
