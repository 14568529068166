import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { SnackService } from '@evo/ui/common';
import { oneAppConfig } from '@evo/iso/common';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultApi } from '@evo/generated/account';

@Component({
  templateUrl: './cart-modal.component.html',
})
export class CartModalComponent implements OnInit {
  oneAppConfig = oneAppConfig;
  counts = new Array(100).fill(0);
  form: FormGroup;

  constructor(
    public cartSvc: CartService,
    private snackBar: SnackService,
    public dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private api: DefaultApi
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      count: [1],
      promo: [null],
    });
  }

  async checkout(apps: any[]) {
    try {
      const { url } = (
        await this.api.stripeControllerCheckoutSession({
          facilityCount: this.form?.value?.count,
          products: apps.map((app) => app.app),
          promo: this.form?.value?.promo,
        })
      ).data;

      window.location.href = url;
    } catch (error) {
      console.warn(error);
      this.snackBar.oops();
    }
  }
}
