<h1 class="mat-h1">{{ product?.label }}</h1>
<h2 class="mat-body-2">One-Year License</h2>
<div *ngIf="product.app.includes('ADM')">
  <mat-list>
    <mat-list-item>
      Bundle Federal Admissions + 1 State Admissions app and get a 10% discount
    </mat-list-item>
    <mat-list-item>
      Bundle Federal Admissions + 2 State Admissions apps and get a 20% discount
    </mat-list-item>
  </mat-list>
  <evo-price-admissions></evo-price-admissions>
</div>
<div *ngIf="product.app.includes('COM')">
  <mat-list>
    <mat-list-item
      >Includes compliance reporting and notifications</mat-list-item
    >
  </mat-list>
  <evo-price-compliance></evo-price-compliance>
</div>
<mat-toolbar class="mt-4">
  <div class="row space-between">
    <div class="row space-between">
      <div class="row button-group">
        <a
          mat-raised-button
          color="accent"
          [href]="product.marketing"
          target="_blank"
        >
          <mat-icon>open_in_new</mat-icon> Learn More
        </a>
        <button
          mat-raised-button
          color="primary"
          (click)="cartSvc.addToCart(product)"
          *ngIf="!cartSvc.isInCart(product)"
        >
          <mat-icon>add_shopping_cart</mat-icon> Add to Cart
        </button>
        <button
          mat-raised-button
          color="warn"
          (click)="cartSvc.removeFromCart(product)"
          *ngIf="cartSvc.isInCart(product)"
        >
          <mat-icon>remove_shopping_cart</mat-icon> Remove from Cart
        </button>
        <button
          color="primary"
          mat-raised-button
          (click)="goToCart()"
          *ngIf="(cartSvc.items$ | async)?.length"
        >
          <mat-icon>shopping_cart</mat-icon> View Cart
        </button>
      </div>
      <div>
        <button mat-raised-button color="warn" (click)="dialogRef.close()">
          <mat-icon>close</mat-icon> Close
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>
<div class="mat-caption">Start checkout to see pricing</div>
