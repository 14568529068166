import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { oneAppConfig } from '@evo/iso/common';
import { Config } from '@evo/ui/common';
import { map, of } from 'rxjs';

@Component({
  selector: 'evo-app-buttons',
  templateUrl: './app-buttons.component.html',
  styleUrls: ['./app-buttons.component.scss'],
})
export class AppButtonsComponent implements OnInit {
  @Input() groups: string[];
  @Output() select = new EventEmitter<any>();
  apps: any;

  constructor(private config: Config) {}

  ngOnInit(): void {
    this.apps = of((this.groups || []).sort()).pipe(
      map((groups) =>
        groups
          .map((group) => {
            const config = oneAppConfig[group];
            if (!config) {
              return;
            }

            if (config.app === 'COM') {
              return {
                ...config,
                group,
                url: this.config.uiUrlCompliance + '/dashboard',
              };
            }

            return { ...config, group };
          })
          .filter(Boolean)
      )
    );
  }
}
