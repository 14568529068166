import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultApi } from '@evo/generated/account';
import { BaseComponent, EvoCookieService, SnackService } from '@evo/ui/common';

@Component({
  templateUrl: './mfa.component.html',
})
export class MfaComponent extends BaseComponent implements OnInit {
  formGroupMfa!: FormGroup;
  redirectUrl: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackService,
    private router: Router,
    private cookieService: EvoCookieService,
    private api: DefaultApi,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.redirectUrl = this.route.snapshot.paramMap.get('redirect');
    if (!this.cookieService.getEmail()) {
      this.router.navigate(['/login']);
    }

    this.formGroupMfa = this.formBuilder.group({
      mfa: [null, Validators.required],
    });
  }

  handleError(e: Error) {
    console.warn(e);
    this.busy$$.next(false);
    this.snackBar.oops();
    this.changeDetectorRef.detectChanges();
  }

  async validateMfa(mfa: string) {
    const username = this.cookieService.getEmail();
    this.busy$$.next(true);
    try {
      const {
        token, idToken
      } = (await this.api.authControllerValidateMfaCode({ mfa, username })).data;
      if (token) {
        this.cookieService.setSessionTokens(token, idToken);
        this.cookieService.deleteEmail();
        if (this.redirectUrl) {
          window.location.href = this.redirectUrl;
        } else {
          this.router.navigate(['/']);
        }
      }
    } catch (error: any) {
      this.handleError(error);
    }
  }
}
