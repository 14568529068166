import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { calculateCompliancePrice } from '@evo/iso/common';

@Component({
  selector: 'evo-price-compliance',
  templateUrl: './price-compliance.component.html',
})
export class PriceComplianceComponent implements OnInit {
  calc = calculateCompliancePrice;
  form: FormGroup;
  constructor(private fb: FormBuilder) {}
  counts = new Array(100).fill(0);

  ngOnInit(): void {
    this.form = this.fb.group({
      count: [1],
    });
  }
}
