<div class="apps flex flex-row flex-wrap justify-around">
  <div
    class="app flex flex-row shadow-8 width-full cursor-pointer"
    [ngClass]="app.group"
    *ngFor="let app of apps | async"
    [ngStyle]="app.style"
    (click)="select.emit(app)"
  >
    <img [src]="'assets/' + app.svg" />
    <div class="spacer"></div>
    <div>{{ app.label }}</div>
  </div>
</div>
