<table class="width-full mt-4">
  <thead>
    <tr>
      <th>Number of SNFs</th>
      <th>Federal First Year</th>
      <th>Ohio First Year</th>
      <th>Michigan First Year</th>
      <th>Federal Update</th>
      <th>Ohio Update</th>
      <th>Michigan Update</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1 to 2</td>
      <td>$ 749.00</td>
      <td>$ 999.00</td>
      <td>$ 999.00</td>
      <td>$ 199.00</td>
      <td>$ 249.00</td>
      <td>$ 249.00</td>
    </tr>
    <tr>
      <td>1 to 2</td>
      <td>$ 749.00</td>
      <td>$ 999.00</td>
      <td>$ 999.00</td>
      <td>$ 199.00</td>
      <td>$ 249.00</td>
      <td>$ 249.00</td>
    </tr>
    <tr>
      <td>3 to 5</td>
      <td>$ 999.00</td>
      <td>$ 1,249.00</td>
      <td>$ 1,249.00</td>
      <td>$ 249.00</td>
      <td>$ 299.00</td>
      <td>$ 299.00</td>
    </tr>
    <tr>
      <td>6 to 10</td>
      <td>$ 1,249.00</td>
      <td>$ 1,499.00</td>
      <td>$ 1,499.00</td>
      <td>$ 299.00</td>
      <td>$ 349.00</td>
      <td>$ 349.00</td>
    </tr>
    <tr>
      <td>11 to 20</td>
      <td>$ 1,499.00</td>
      <td>$ 1,749.00</td>
      <td>$ 1,749.00</td>
      <td>$ 349.00</td>
      <td>$ 399.00</td>
      <td>$ 399.00</td>
    </tr>
    <tr>
      <td>21 to 35</td>
      <td>$ 1,749.00</td>
      <td>$ 1,999.00</td>
      <td>$ 1,999.00</td>
      <td>$ 399.00</td>
      <td>$ 449.00</td>
      <td>$ 449.00</td>
    </tr>
    <tr>
      <td>36+</td>
      <td>$ 1,999.00</td>
      <td>$ 2,149.00</td>
      <td>$ 2,149.00</td>
      <td>$ 449.00</td>
      <td>$ 499.00</td>
      <td>$ 499.00</td>
    </tr>
  </tbody>
</table>
