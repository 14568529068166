<h1 class="mat-h1">Licenses Selected For Purchase</h1>
<h2 class="mat-body-2">
  Discounts will be calculated in checkout before you submit your payment
  information
</h2>

<div class="py-4">
  <form [formGroup]="form" novalidate>
    <mat-form-field class="width-full" appearance="outline">
      <input
        matInput
        placeholder="promo code (optional)"
        formControlName="promo"
      />
    </mat-form-field>

    <mat-label>Number of Skilled Nursing Facilities</mat-label>
    <mat-form-field class="width-full" appearance="outline">
      <mat-select formControlName="count">
        <mat-option *ngFor="let num of counts; let i = index" [value]="i + 1">
          {{ i + 1 }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div
  class="row space-between width-full"
  *ngFor="let product of cartSvc.items$ | async"
>
  <div class="mat-body-2 py-2">{{ oneAppConfig[product.app].label }}</div>

  <div>
    <button
      mat-raised-button
      color="warn"
      (click)="cartSvc.removeFromCart(product)"
    >
      <mat-icon>close</mat-icon> Remove
    </button>
  </div>
</div>

<mat-toolbar class="mt-4 width-full">
  <div class="row space-between">
    <button
      color="primary"
      mat-raised-button
      (click)="checkout(cartSvc.items$$.value)"
      [disabled]="cartSvc.items$$.value.length === 0"
    >
      <mat-icon>verified</mat-icon> Checkout
    </button>
    <button color="warn" mat-raised-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon> Close
    </button>
  </div>
</mat-toolbar>
<div class="mat-caption">Start checkout to see pricing</div>
