import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EvoCookieService, SnackService } from '@evo/ui/common';
import { DefaultApi } from '@evo/generated/account';
import { formatRegexes } from '@evo/ui/angular-serializable-forms';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  formGroup!: FormGroup;
  busy$$ = new BehaviorSubject(false);
  busy$ = this.busy$$.asObservable();
  redirect: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: EvoCookieService,
    private changeDetectorRef: ChangeDetectorRef,
    private api: DefaultApi
  ) { }

  ngOnInit(): void {
    if (this.cookieService.getAuthToken()) {
      this.router.navigate(['/']);
    }

    this.formGroup = this.formBuilder.group({
      name: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.email),
        ],
      }),
      password: [null, Validators.required],
    });

    this.redirect = this.route.snapshot.paramMap.get('redirect');
  }

  async login(creds: { name: string; password: string }) {
    this.busy$$.next(true);

    try {
      await this.api.authControllerAuthenticate(creds);

      this.cookieService.setEmail(creds.name);

      this.busy$$.next(false);
      this.changeDetectorRef.detectChanges();
      this.router.navigate(['/', 'mfa', this.redirect ?? '']);
    } catch (error: any) {
      this.handleError(error);
    }
  }

  handleError(e: Error) {
    console.warn(e);
    this.busy$$.next(false);
    this.snackBar.oops();
    this.changeDetectorRef.detectChanges();
  }
}
