import { Routes } from '@angular/router';
import { OneAppPageComponent } from './pages/one-app-page/one-app-page.component';
import { PaymentConfirmationPageComponent } from './pages/payment-confirmation-page/payment-confirmation-page.component';


export const routes: Routes = [
  {
    path: 'payment-confirmation/:session',
    component: PaymentConfirmationPageComponent,
    pathMatch: 'full',
  },
  { path: '', component: OneAppPageComponent, pathMatch: 'full' },
] as any;
