import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CartService {
  items$$ = new BehaviorSubject<any[]>([]);
  items$ = this.items$$.asObservable();

  addToCart(product: any) {
    this.items$$.next([...this.items$$.value, product]);
  }

  removeFromCart(product: any) {
    this.items$$.next(this.items$$.value.filter((i) => i.app !== product.app));
  }

  isInCart(product: any) {
    return this.items$$.value.some((i) => i.app === product.app);
  }
}
