<evo-modal-page>
  <form class="mt-2" [formGroup]="formGroup">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>email</mat-icon>
      <input matInput type="text" formControlName="name" placeholder="Email" />
    </mat-form-field>
    <evo-error name="name" [form]="formGroup"></evo-error>

    <mat-form-field appearance="outline">
      <mat-icon matPrefix>password</mat-icon>
      <input
        matInput
        type="password"
        formControlName="password"
        placeholder="Password"
      />
    </mat-form-field>
    <evo-error name="password" [form]="formGroup"></evo-error>

    <button
      color="primary"
      class="xl"
      (click)="login(formGroup.value)"
      [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine"
      mat-raised-button
      *ngIf="!(busy$ | async)"
    >
      Login
    </button>
    <div *ngIf="busy$ | async" class="row">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
  </form>

  <mat-toolbar class="dark mt-2 height-auto">
    <div class="flex flex-row flex-wrap justify-center width-full">
      <a mat-button routerLink="/signup">Register</a>
      <a mat-button routerLink="/forgot">Reset Password</a>
    </div>
  </mat-toolbar>
</evo-modal-page>
